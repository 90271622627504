import { SquareLengthCalculator } from '../Utilities/SquareLengthCalculator';
import { ViewSystemParameters } from '../Types/ViewSystemParameters';
import { InspectionStepImageParameter } from '../Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from '../Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../Types/InspectionStepResultSquare';

export class ViewportRenderingService {
    private _squareLengthCalculator: SquareLengthCalculator;

    constructor(squareLengthCalculator: SquareLengthCalculator) {
        this._squareLengthCalculator = squareLengthCalculator;
    }

    public onUpdateRendering?: (canvas: HTMLCanvasElement) => void;

    public Rendering(currentViewSystemParameters: ViewSystemParameters, innerCanvas: HTMLCanvasElement,
            availableSquareInfos: Array<AvailableSquareInfo>, inspectionStepResultSquares: Array<InspectionStepResultSquare>,
            inspectionStepImageParameter: InspectionStepImageParameter): void {
        const renderCanvas = document.createElement('canvas');
        renderCanvas.width = currentViewSystemParameters.ViewportSize.Width;
        renderCanvas.height = currentViewSystemParameters.ViewportSize.Height;
        
        if(renderCanvas !== null) {
            const ctx = renderCanvas.getContext('2d');
            
            if(ctx !== null) {
                const cropBitmap = document.createElement('canvas');
                cropBitmap.width = currentViewSystemParameters.BitmapRealm.Width;
                cropBitmap.height = currentViewSystemParameters.BitmapRealm.Height;
                const cropBitmapCtx = cropBitmap.getContext('2d');
                if(cropBitmapCtx !== null) {
                    cropBitmapCtx.drawImage(innerCanvas, currentViewSystemParameters.BitmapRealm.X, currentViewSystemParameters.BitmapRealm.Y,
                                            currentViewSystemParameters.BitmapRealm.Width, currentViewSystemParameters.BitmapRealm.Height,
                                            0, 0, currentViewSystemParameters.BitmapRealm.Width, currentViewSystemParameters.BitmapRealm.Height);
                }
                
                ctx.beginPath();
                ctx.drawImage(cropBitmap,
                    currentViewSystemParameters.BitmapRealmToViewport.X,
                    currentViewSystemParameters.BitmapRealmToViewport.Y,
                    currentViewSystemParameters.BitmapRealmToViewport.Width,
                    currentViewSystemParameters.BitmapRealmToViewport.Height);
                ctx.closePath();
                // *********
                // DEBUG
                // *********
                /*
                ctx.fillStyle = "#000000aa";
                ctx.strokeStyle = '#ffffff00';
                ctx.lineWidth = 0.3;
                ctx.beginPath();
                currentViewSystemParameters.SelectorGrid.Squares.forEach((s) => {
                    if(s.Index === -1) {
                        const rectBorderX = currentViewSystemParameters.SelectorGrid.HorizontalSteps[s.StartStepHorizontal];
                        const rectBorderY = currentViewSystemParameters.SelectorGrid.VerticalSteps[s.StartStepVertical];
                        const rectBorderWidth = this._squareLengthCalculator.GetHorizontalSquareLength(currentViewSystemParameters.SelectorGrid, s.Index);
                        const rectBorderHeight = this._squareLengthCalculator.GetVerticalSquareLength(currentViewSystemParameters.SelectorGrid, s.Index);
                        if(ctx !== null) {
                            ctx.rect(rectBorderX, rectBorderY, rectBorderWidth, rectBorderHeight);
                            ctx.fillRect(rectBorderX, rectBorderY, rectBorderWidth, rectBorderHeight);
                        }
                    }
                });
                ctx.stroke();
                ctx.closePath();
                */
                // *********
                // END DEBUG
                // *********
                ctx.fillStyle = inspectionStepImageParameter.SquareFillColor;
                ctx.strokeStyle = inspectionStepImageParameter.SquareBorderColor;
                ctx.lineWidth = inspectionStepImageParameter.SquareBorderStroke;
                ctx.beginPath();

                currentViewSystemParameters.SelectorGrid.Squares.forEach((s) => {
                    if(s.Index > -1) {
                        let extensionFactorX = 0;
                        let extensionFactorY = 0;

                        if(availableSquareInfos !== undefined && availableSquareInfos.length > 0) {
                            const asi = availableSquareInfos.find((a) => a.Index === s.Index);
                            if (asi !== undefined) {
                                extensionFactorX = asi.ExtensionX + 1;
                                extensionFactorY = asi.ExtensionY + 1;
                            }
                        } else {
                            extensionFactorX = 1;
                            extensionFactorY = 1;
                        }

                        if(extensionFactorX > 0 && extensionFactorY > 0) {
                            const rectBorderX = currentViewSystemParameters.SelectorGrid.HorizontalSteps[s.StartStepHorizontal];
                            const rectBorderY = currentViewSystemParameters.SelectorGrid.VerticalSteps[s.StartStepVertical];
                            const rectBorderWidth = this._squareLengthCalculator.GetHorizontalSquareLength(currentViewSystemParameters.SelectorGrid, s.Index) * extensionFactorX;
                            const rectBorderHeight = this._squareLengthCalculator.GetVerticalSquareLength(currentViewSystemParameters.SelectorGrid, s.Index) * extensionFactorY;
                            if(ctx !== null) {
                                ctx.rect(rectBorderX, rectBorderY, rectBorderWidth, rectBorderHeight);
                            }
                            
                            if(inspectionStepResultSquares !== undefined) {
                                const i = inspectionStepResultSquares.find((rs: InspectionStepResultSquare) => rs.Index === s.Index);
                                if(i !== undefined) {
                                    ctx.fillRect(rectBorderX, rectBorderY, rectBorderWidth, rectBorderHeight);
                                }
                            }
                        }
                    }
                });
                ctx.stroke();
                ctx.closePath();
                
                if (this.onUpdateRendering) {
                    this.onUpdateRendering(renderCanvas);
                }
            } else {
                console.log("ctx of pre-render-canvas is null");
            }
        }
    }
}
