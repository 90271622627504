import { SelectorGrid } from '../Types/SelectorGrid';

export class SquareLengthCalculator {
    public GetHorizontalSquareLength(selectorGrid: SelectorGrid, squareIndex: number): number {
        const square = selectorGrid.Squares.find(i => i.Index === squareIndex);

        if(square === undefined) {
            throw new Error('GetHorizontalSquareLength(): square index not found!');
        }

        if (square.StartStepHorizontal + 1 >= selectorGrid.HorizontalSteps.length || square.StartStepHorizontal < 0) {
            throw new Error('GetHorizontalSquareLength(): end step index too small or large!');
        }

        return selectorGrid.HorizontalSteps[square.StartStepHorizontal + 1] - selectorGrid.HorizontalSteps[square.StartStepHorizontal];
    }

    public GetVerticalSquareLength(selectorGrid: SelectorGrid, squareIndex: number): number {
        const square = selectorGrid.Squares.find(i => i.Index === squareIndex);

        if(square === undefined) {
            throw new Error('GetVerticalSquareLength(): square index not found!');
        }

        if (square.StartStepVertical + 1 >= selectorGrid.VerticalSteps.length || square.StartStepVertical < 0) {
            throw new Error('GetVerticalSquareLength(): end step index too small or large!');
        }

        return selectorGrid.VerticalSteps[square.StartStepVertical + 1] - selectorGrid.VerticalSteps[square.StartStepVertical];
    }
}
