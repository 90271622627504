




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
// ---

import { Point2d } from './Types/_base/Point2d';
import { InspectionStepResultSquare } from './Types/InspectionStepResultSquare';
import { TooltipContent } from './Types/TooltipContent';

@Component({})
export default class ResultToolTip extends Vue {
    private currentTop!: number;
    private currentLeft!: number;
    private mustVisible!: boolean;
    private componentVisibilityByPositiveMousePositionThreshold!: {x: number; y: number}

    constructor() {
        super();

        this.currentTop = 0;
        this.currentLeft = 0;
        this.mustVisible = false;
        this.componentVisibilityByPositiveMousePositionThreshold = { x: 16, y: 16 };
    }

    @Prop({
        default: function () {
            return {
                X: 0,
                Y: 0
            };
        }
    })
    toolTipPosition!: Point2d;

    @Watch('toolTipPosition', { immediate: false, deep: true })
        // eslint-disable-next-line
        onChangedToolTipPosition(value: Point2d, oldValue: Point2d) {
            this.currentTop = value.Y;
            this.currentLeft = value.X;
            this.mustVisible = true;
        }

    @Prop({
        default: function () {
            return new InspectionStepResultSquare(0, '#ffffff', '#ffffff', '', new Array<TooltipContent>());
        }
    })
    inspectionStepResultSquare!: InspectionStepResultSquare;

    tooltipMouseMove(e: MouseEvent) {
        if(e.offsetX > this.componentVisibilityByPositiveMousePositionThreshold.x ||
            e.offsetY > this.componentVisibilityByPositiveMousePositionThreshold.y) {
                this.mustVisible = false;
        }
    }
}
