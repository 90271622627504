export class ViewportControlParameters {
    private _zoom: number;
    private _offsetX: number;
    private _offsetY: number;
    private _halfOverlapX: number;
    private _halfOverlapY: number;

    constructor(zoom: number, offsetX: number, offsetY: number, halfOverlapX: number, halfOverlapY: number) {
        this._zoom = zoom;
        this._offsetX = offsetX;
        this._offsetY = offsetY;
        this._halfOverlapX = halfOverlapX;
        this._halfOverlapY = halfOverlapY;
    }

    public get Zoom() { return this._zoom; };
    public get OffsetX() { return this._offsetX; };
    public get OffsetY() { return this._offsetY; };
    public get HalfOverlapX() { return this._halfOverlapX; };
    public get HalfOverlapY() { return this._halfOverlapY; };

    // ----

    public SetZoom(zoom: number): void {
        this._zoom = zoom;
    }

    public SetOffsetX(offsetX: number): void {
        this._offsetX = offsetX;
    }

    public SetOffsetY(offsetY: number): void {
        this._offsetY = offsetY;
    }

    public SetHalfOverlapX(halfOverlapX: number): void {
        this._halfOverlapX = halfOverlapX;
    }

    public SetHalfOverlapY(halfOverlapY: number): void {
        this._halfOverlapY = halfOverlapY;
    }
};
