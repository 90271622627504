export function step11(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 2,
        squareBorderColor: '#ff000040',
        squareFillColor: '#770000c0',
        maskColor: '#ef2f1f',
        squareSize: 40, //20,
        imageScale: 1,
        isViewportChangeable: true,
    };
}

export function step11result() {
    return [
        {
          index: 146,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff0000',
                text: 'Lunker'
              },
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#00ff00',
                text: 'Riss'
              }
          ]
        },
        {
            index: 232,
            backgroundColor: '#3300ffaa',
            textColor: '#ffffff',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                }
            ]
        },
        {
            index: 235,
            backgroundColor: '#3300ffaa',
            textColor: '#ffffff',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                },
                {
                    color: '#00ff00',
                    text: 'Riss'
                },
                {
                    color: '#770077',
                    text: 'Fehlstelle'
                }
            ]
        },
        {
            index: 220,
            backgroundColor: '#3300ffaa',
            textColor: '#ffff00',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                }
            ]
        },
        {
            index: 254,
            backgroundColor: '#ffff00aa',
            textColor: '#ff0000',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle22'
                },
                {
                  color: '#227722',
                  text: 'Kiwi'
                },
            ]
        },
        {
            index: 221,
            backgroundColor: '#ffffffaa',
            textColor: '#000066',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                },
                {
                    color: '#aa00aa',
                    text: 'Kratzer'
                },
            ]
        },
      ];
}

export function step12(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 2,
        squareBorderColor: '#ff000040',
        squareFillColor: '#ff0000c0',
        maskColor: '#ef2f1f',
        squareSize: 20,
        imageScale: 1,
        isViewportChangeable: true,
    };
}

export function step12result() {
  return [
      {
          index: 320,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#aaaaaa',
                text: 'Sonstiges'
              }
          ]
      },
      {
          index: 560,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#aaaaaa',
                text: 'Sonstiges'
              },
              {
                  color: '#00ff00',
                  text: 'Riss'
              },
              {
                  color: '#770077',
                  text: 'Fehlstelle'
              }
          ]
      },
    ];
}

export function step12AvailableSquareInfo() {
  return [
    {
      index: 290,
      extensionX: 4,
      extensionY: 2,
    },
    {
      index: 320,
      extensionX: 2,
      extensionY: 3,
    },
    {
      index: 560,
      extensionX: 5,
      extensionY: 1,
    },
    {
      index: 716,
      extensionX: 4,
      extensionY: 3,
    },
  ];
}

export function step13(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.4,
        squareBorderStroke: 2,
        squareBorderColor: '#ff0040',
        squareFillColor: '#77ff00c0',
        maskColor: '#ef2f1f',
        squareSize: 40, //10
        imageScale: 1,
        isViewportChangeable: true,
    };
}
