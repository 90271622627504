"use strict";

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}
 
// ------------------------------------------------------------------------------------------------------

export function colorValueRgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function colorValueHexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

// ------------------------------------------------------------------------------------------------------

export function colorValueHexToInt(hex) {
    return (colorValueHexToRgb(hex).r << 16) + (colorValueHexToRgb(hex).g << 8) + colorValueHexToRgb(hex).b;
}

export function colorValueHexToIntWithAlpha(hex, alpha) {
    return (alpha << 24) + (colorValueHexToRgb(hex).r << 16) + (colorValueHexToRgb(hex).g << 8) + colorValueHexToRgb(hex).b;
}

// ------------------------------------------------------------------------------------------------------
/**
 * @param  {} hex
 * @param  {} alpha
 */
export function colorValueHexToIntWithAlphaAndSwapRedWithBlue(hex, alpha) {
    return (alpha << 24) + (colorValueHexToRgb(hex).b << 16) + (colorValueHexToRgb(hex).g << 8) + colorValueHexToRgb(hex).r;
}
