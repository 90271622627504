export class ViewportControlConfigurationParameters {
    private _zoomFactorMax: number;
    private _zoomFactorStepValue: number;
    private _offsetPositionStepValue: number;

    constructor(zoomFactorMax: number, zoomFactorStepValue: number, offsetPositionStepValue: number) {
        this._zoomFactorMax = zoomFactorMax;
        this._zoomFactorStepValue = zoomFactorStepValue;
        this._offsetPositionStepValue = offsetPositionStepValue;
    }

    public get ZoomFactorMax() { return this._zoomFactorMax; };
    public get ZoomFactorStepValue() { return this._zoomFactorStepValue; };
    public get OffsetPositionStepValue() { return this._offsetPositionStepValue; };
};
