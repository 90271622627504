import { Point2d } from '../Types/_base/Point2d';
import { Square } from '../Types/Square';
import { SelectorGrid } from '../Types/SelectorGrid';
import { SquaresBoundingBox } from '../Types/SquaresBoundingBox';
import { AvailableSquareInfo } from '../Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../Types/InspectionStepResultSquare';

import { SquareLengthCalculator } from './SquareLengthCalculator';

export class SquareUtilities {
    private _squareLengthCalculator: SquareLengthCalculator;

    constructor(squareLengthCalculator: SquareLengthCalculator) {
        this._squareLengthCalculator = squareLengthCalculator;
    }
    
    public GetSquaresBoundingBox(squares: Array<Square>, callback: (squaresBoundingBox: SquaresBoundingBox) => void): void {
        let smallestHorizontalStep = 0;
        let smallestVerticalStep = 0;
        let largestHorizontalStep = 0;
        let largestVerticalStep = 0;

        if(squares !== undefined && squares.length > 0) {
            smallestHorizontalStep = squares[0].StartStepHorizontal;
            if(smallestHorizontalStep === undefined) {
                smallestHorizontalStep = 0;
            }
            
            smallestVerticalStep = squares[0].StartStepVertical;
            if(smallestVerticalStep === undefined) {
                smallestVerticalStep = 0;
            }

            largestHorizontalStep = squares[0].StartStepHorizontal;
            if(largestHorizontalStep === undefined) {
                largestHorizontalStep = 0;
            }

            largestVerticalStep = squares[0].StartStepVertical;
            if(largestVerticalStep === undefined) {
                largestVerticalStep = 0;
            }

            squares.forEach((s) => {
                if(s.StartStepHorizontal <= smallestHorizontalStep) {
                    smallestHorizontalStep = s.StartStepHorizontal;
                }

                if(s.StartStepVertical <= smallestVerticalStep) {
                    smallestVerticalStep = s.StartStepVertical;
                }

                if(s.StartStepHorizontal > largestHorizontalStep) {
                    largestHorizontalStep = s.StartStepHorizontal;
                }

                if(s.StartStepVertical > largestVerticalStep) {
                    largestVerticalStep = s.StartStepVertical;
                }
            });
        }
        
        callback(new SquaresBoundingBox(
                smallestHorizontalStep,
                smallestVerticalStep,
                largestHorizontalStep,
                largestVerticalStep
            )
        );
    }
    
    // ---

    public FindCoordinatesInResultSquare(selectorGrid: SelectorGrid, availableSquareInfos: Array<AvailableSquareInfo>,
            inspectionStepResultSquares: Array<InspectionStepResultSquare>,
            position: Point2d, callback: (index: number) => void): void {
        let callbackValue = -1;

        if(inspectionStepResultSquares.length > 0) {
            selectorGrid.Squares.forEach((s) => {
                const i = inspectionStepResultSquares.find((rs: InspectionStepResultSquare) => rs.Index === s.Index);
                if(i !== undefined) {
                    let extensionFactorX = 0;
                    let extensionFactorY = 0;
                    
                    if(availableSquareInfos !== undefined && availableSquareInfos.length > 0) {
                        const asi = availableSquareInfos.find((a) => a.Index === s.Index);
                        if (asi !== undefined) {
                            extensionFactorX = asi.ExtensionX + 1;
                            extensionFactorY = asi.ExtensionY + 1;
                        }
                    } else {
                        extensionFactorX = 1;
                        extensionFactorY = 1;
                    }

                    const case1: boolean = position.X >= selectorGrid.HorizontalSteps[s.StartStepHorizontal];
                    const case2: boolean = position.X < selectorGrid.HorizontalSteps[s.StartStepHorizontal] +
                                            (this._squareLengthCalculator.GetHorizontalSquareLength(selectorGrid, s.Index) * extensionFactorX) - 1;
                    const case3: boolean = position.Y >= selectorGrid.VerticalSteps[s.StartStepVertical];
                    const case4: boolean = position.Y < selectorGrid.VerticalSteps[s.StartStepVertical] +
                                            (this._squareLengthCalculator.GetVerticalSquareLength(selectorGrid, s.Index) * extensionFactorY) - 1;
    
                    if (case1 && case2 && case3 && case4) {
                        callbackValue = s.Index;
                    }
                }
            });
        }
        callback(callbackValue);
    }

    // ---
    
    public FindCoordinatesInSquare(selectorGrid: SelectorGrid, availableSquareInfos: Array<AvailableSquareInfo>,
            position: Point2d, callback: (index: number) => void): void {
        let callbackValue = -1;

        selectorGrid.Squares.forEach((s) => {
            let extensionFactorX = 0;
            let extensionFactorY = 0;
            
            if(availableSquareInfos !== undefined && availableSquareInfos.length > 0) {
                const asi = availableSquareInfos.find((a) => a.Index === s.Index);
                if (asi !== undefined) {
                    extensionFactorX = asi.ExtensionX + 1;
                    extensionFactorY = asi.ExtensionY + 1;
                }
            } else {
                extensionFactorX = 1;
                extensionFactorY = 1;
            }

            const case1: boolean = position.X >= selectorGrid.HorizontalSteps[s.StartStepHorizontal];
            const case2: boolean = position.X < selectorGrid.HorizontalSteps[s.StartStepHorizontal] +
                                    (this._squareLengthCalculator.GetHorizontalSquareLength(selectorGrid, s.Index) * extensionFactorX) - 1;
            const case3: boolean = position.Y >= selectorGrid.VerticalSteps[s.StartStepVertical];
            const case4: boolean = position.Y < selectorGrid.VerticalSteps[s.StartStepVertical] +
                                    (this._squareLengthCalculator.GetVerticalSquareLength(selectorGrid, s.Index) * extensionFactorY) - 1;

            if (case1 && case2 && case3 && case4) {
                callbackValue = s.Index;
            }
        });
        
        callback(callbackValue);
    }
}
