






























import Vue from 'vue';
import Component from 'vue-class-component';

import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters([
            'resultSquaresTotalCount',
        ]),
    },
})
export default class Navbar extends Vue {
};
