<template>
    <div class="container-fluid">
        <p>Mount Unmount direct</p>
        <div class="row my-2">
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setVisible()"
            >GSV Visible</button>
             <button
                    class="btn button-1 mx-2 my-1"
                    @click="setUnvisible()"
            >GSV Unvisible</button>
        </div>
        <div class="row my-2">
            <div class="col-xl-9">
                <div class="m-3">
                    <p>----</p>
                    <div
                        v-if="isGridSystemVisible"
                    >
                        <inspection-result-grid-system
                            :viewportControlConfigurationParameters="currentViewportControlConfigurationParameters"
                            :viewportSize="currentViewportSize"
                            :maskColorAsColor="currentMaskColorAsColor"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter"
                            :availableSquareInfos="currentAvailableSquareInfos"
                            :inspectionStepResultSquares="currentInspectionResultSquares"
                            :changedViewport="changedViewport"
                            
                        ></inspection-result-grid-system>
                    </div>
                    <div
                        v-if="!isGridSystemVisible"
                    >
                        <p>else no grid</p>
                    </div>
                    <p>----</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

import { Size2d } from '../components/InspectionResultGridSystem/Types/_base/Size2d';
import { InspectionStepImageParameter } from '../components/InspectionResultGridSystem/Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from '../components/InspectionResultGridSystem/Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../components/InspectionResultGridSystem/Types/InspectionStepResultSquare';
import { TooltipContent } from '../components/InspectionResultGridSystem/Types/TooltipContent';
import InspectionResultGridSystem from '@/components/InspectionResultGridSystem/InspectionResultGridSystem';

import { step11, step11result } from './dataFiller/dataFiller1';

export default {
    name: 'MountUnmountTest',
    components: {
        InspectionResultGridSystem
    },
    data: function () {
        return {
            isGridSystemVisible: true,
            
            currentViewportSize: new Size2d(1000, 800),
            currentViewportControlConfigurationParameters: {
            ZoomFactorMax: 5.0,
            ZoomFactorStepValue: 0.2,
            OffsetPositionStepValue: 50
          },
          currentMaskColorAsColor: "#000055",
          currentInspectionStepImageParameter: undefined,
          currentAvailableSquareInfos: undefined,
          currentInspectionResultSquares: undefined,
          changedViewport: 0,
        };
    },
    created: function() {
      this.demo11();
    },
    methods: {
        setVisible() {
            this.isGridSystemVisible = true;
        },
        setUnvisible() {
            this.isGridSystemVisible = false;
        },
        demo11() {
            this.loadInspectionStepImageParameter('testImages/01.png', step11);
            this.currentAvailableSquareInfos = [];
            this.loadInspectionResultSquares(step11result);
        },
        loadImage(url) {
          return axios
            .get(url, {
              responseType: 'arraybuffer'
            })
            .then((response) => {
              return {
                error: 0,
                errorObject: undefined,
                data: Buffer.from(response.data, 'binary').toString('base64')
              };
            })
            .catch((error) => {
              return {
                error: 1,
                errorObject: error,
                data: undefined
              };
            });
        },
        loadInspectionStepImageParameter(url, initFunction) {
            this.text4 = '';
            const imageBuffer = this.loadImage(url);
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  //console.log('Laden OK:', iB.error);
                  const currentInspectionStepImageParameterObject = initFunction("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
              });
        },
        loadAvailableSquareInfos(initFunction) {
            this.currentAvailableSquareInfos = [];
            const asi = initFunction();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos.push(b);
            });
        },
        loadInspectionResultSquares(initFunction) {
          this.currentInspectionResultSquares = [];
          const irs = initFunction();
          irs.forEach((i) => {
              const ttc = [];
              i.content.forEach((c) => {
                const cc = new TooltipContent(c.color, c.text);
                ttc.push(cc);
              });
              let headerText = '';
              if (i.headerText !== undefined) {
                headerText = i.headerText;
              }
              const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
              this.currentInspectionResultSquares.push(ii);
          });
        }
  }
};
</script>
