import { Square } from './Square';

export class SelectorGrid {
    private _horizontalSteps: Array<number>;
    private _verticalSteps: Array<number>;
    private _squares: Array<Square>;

    constructor(horizontalSteps: Array<number>, verticalSteps: Array<number>, squares: Array<Square>) {
        this._horizontalSteps = horizontalSteps;
        this._verticalSteps = verticalSteps;
        this._squares = squares;
    }

    public get HorizontalSteps() { return this._horizontalSteps; };
    public get VerticalSteps() { return this._verticalSteps; };
    public get Squares() { return this._squares; };
};
