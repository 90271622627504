import { ViewportControlConfigurationParameters } from '../Types/ViewportControlConfigurationParameters';
import { UserInterfaceChangedViewportOptions } from '../Types/UserInterfaceChangedViewportOptions';
import { ViewportControlParameters } from '../Types/ViewportControlParameters';

export class ViewportController {
    private _viewportControlConfiguration: ViewportControlConfigurationParameters;
    private _currentViewportControlParameters: ViewportControlParameters;

    constructor(viewportControlConfiguration: ViewportControlConfigurationParameters) {
        this._viewportControlConfiguration = viewportControlConfiguration;
        this._currentViewportControlParameters = new ViewportControlParameters(1.0, 0, 0, 0, 0);
    }
    
    public get CurrentViewportControlParameters() { return this._currentViewportControlParameters; };
    
    // ---
    public SetViewportControlConfiguration(zoomFactorMax: number, zoomFactorStepValue: number, offsetPositionStepValue: number): void {
        this._viewportControlConfiguration = new ViewportControlConfigurationParameters(zoomFactorMax, zoomFactorStepValue, offsetPositionStepValue);
    }

    public ResetViewport(): void {
        this._currentViewportControlParameters.SetZoom(0.0);
        this._currentViewportControlParameters.SetOffsetX(0);
        this._currentViewportControlParameters.SetOffsetY(0);
        this._currentViewportControlParameters.SetHalfOverlapX(0);
        this._currentViewportControlParameters.SetHalfOverlapY(0);
    }

    public UpdateViewportExplicit(zoom: number, offsetX: number, offsetY: number): void {
        this._currentViewportControlParameters.SetZoom(zoom);
        this._currentViewportControlParameters.SetOffsetX(offsetX);
        this._currentViewportControlParameters.SetOffsetY(offsetY);
    }

    public SetHalfOverlap(halfOverlapX: number, halfOverlapY: number): void {
        const saveOldHalfOverlapX = this._currentViewportControlParameters.HalfOverlapX;
        const saveOldHalfOverlapY = this._currentViewportControlParameters.HalfOverlapY;

        if (halfOverlapX < 0) {
            this._currentViewportControlParameters.SetHalfOverlapX(Math.floor(Math.abs(halfOverlapX)));
            if (saveOldHalfOverlapX !== 0) {
                const newOffsetX = Math.floor((this._currentViewportControlParameters.HalfOverlapX / saveOldHalfOverlapX) * this._currentViewportControlParameters.OffsetX);
                this._currentViewportControlParameters.SetOffsetX(newOffsetX);
            }
        } else {
            this._currentViewportControlParameters.SetHalfOverlapX(0);
            this._currentViewportControlParameters.SetOffsetX(0);
        }

        if (halfOverlapY < 0) {
            this._currentViewportControlParameters.SetHalfOverlapY(Math.floor(Math.abs(halfOverlapY)));
            if (saveOldHalfOverlapY !== 0) {
                const newOffsetY = Math.floor((this._currentViewportControlParameters.HalfOverlapY / saveOldHalfOverlapY) * this._currentViewportControlParameters.OffsetY);
                this._currentViewportControlParameters.SetOffsetY(newOffsetY);
            }
        } else {
            this._currentViewportControlParameters.SetHalfOverlapY(0);
            this._currentViewportControlParameters.SetOffsetY(0);
        }
    }
    
    public UpdateViewportWithButton(changedViewportFlags: UserInterfaceChangedViewportOptions, callback: () => void): void {
        if (changedViewportFlags & UserInterfaceChangedViewportOptions.ZoomOut) {
                if (this._currentViewportControlParameters.Zoom - this._viewportControlConfiguration.ZoomFactorStepValue > 1.0) {
                    this.CurrentViewportControlParameters.SetZoom(
                        this.CurrentViewportControlParameters.Zoom - this._viewportControlConfiguration.ZoomFactorStepValue);
                } else {
                    this._currentViewportControlParameters.SetZoom(1.0);
                }
            }
        if (changedViewportFlags & UserInterfaceChangedViewportOptions.ZoomIn) {
            if (this._currentViewportControlParameters.Zoom + this._viewportControlConfiguration.ZoomFactorStepValue < this._viewportControlConfiguration.ZoomFactorMax) {
                this.CurrentViewportControlParameters.SetZoom(
                    this.CurrentViewportControlParameters.Zoom + this._viewportControlConfiguration.ZoomFactorStepValue);
            } else {
                this._currentViewportControlParameters.SetZoom(this._viewportControlConfiguration.ZoomFactorMax);
            }
        }

        //// --------------------------------------------------------------------------------------

        const offsetStepValue = this._viewportControlConfiguration.OffsetPositionStepValue;
        const offsetX = this._currentViewportControlParameters.OffsetX;
        const offsetY = this._currentViewportControlParameters.OffsetY;
        const halfOverlapX = this._currentViewportControlParameters.HalfOverlapX;
        const halfOverlapY = this._currentViewportControlParameters.HalfOverlapY;

        if (changedViewportFlags & UserInterfaceChangedViewportOptions.OffsetNegativeX) {
            if ((offsetX - offsetStepValue) < (Math.abs(halfOverlapX) * (-1))) {
                this._currentViewportControlParameters.SetOffsetX(Math.abs(halfOverlapX) * (-1));
            } else {
                this._currentViewportControlParameters.SetOffsetX(offsetX - offsetStepValue);
            }
        }

        if (changedViewportFlags & UserInterfaceChangedViewportOptions.OffsetPositiveX) {
                if ((offsetX + offsetStepValue) > Math.abs(halfOverlapX)) {
                    this._currentViewportControlParameters.SetOffsetX(Math.abs(halfOverlapX));
                } else {
                    this._currentViewportControlParameters.SetOffsetX(offsetX + offsetStepValue);
                }
            }

        if (changedViewportFlags & UserInterfaceChangedViewportOptions.OffsetNegativeY) {
            if ((offsetY - offsetStepValue) < (Math.abs(halfOverlapY) * (-1))) {
                this._currentViewportControlParameters.SetOffsetY(Math.abs(halfOverlapY) * (-1));
            } else {
                this._currentViewportControlParameters.SetOffsetY(offsetY - offsetStepValue);
            }
        }

        if (changedViewportFlags & UserInterfaceChangedViewportOptions.OffsetPositiveY) {
            if ((offsetY + offsetStepValue) > Math.abs(halfOverlapY)) {
                this.CurrentViewportControlParameters.SetOffsetY(Math.abs(halfOverlapY));
            } else {
                this.CurrentViewportControlParameters.SetOffsetY(offsetY + offsetStepValue);
            }
        }
        callback();
    }
}
