import { Point2d } from './Types/_base/Point2d';
import { Size2d } from './Types/_base/Size2d';
import { Rectangle } from './Types/_base/Rectangle';

// ---

import { SelectorGrid } from './Types/SelectorGrid';
import { Square } from './Types/Square';
import { InspectionStepImageParameter } from './Types/InspectionStepImageParameter';
import { ViewportControlConfigurationParameters } from './Types/ViewportControlConfigurationParameters';
import { ViewportControlParameters } from './Types/ViewportControlParameters';
import { ViewSystemParameters } from './Types/ViewSystemParameters';
import { UserInterfaceChangedViewportOptions } from './Types/UserInterfaceChangedViewportOptions';
import { AvailableSquareInfo } from './Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from './Types/InspectionStepResultSquare';

// ---

import { CurrentViewSystemCalculator } from './Core/CurrentViewSystemCalculator';
import { MinimizedGridCalculator } from './Core/MinimizedGridCalculator';
import { NormalizedViewSystemCalculator } from './Core/NormalizedViewSystemCalculator';
import { OriginalGridCalculator } from './Core/OriginalGridCalculator';
import { OuterSquaresFinder } from './Core/OuterSquaresFinder';
import { ViewportController } from './Core/ViewportController';
import { ViewportRenderingService } from './Core/ViewportRenderingService';

// ---

import { SquareLengthCalculator } from './Utilities/SquareLengthCalculator';
import { SquareUtilities } from './Utilities/SquareUtilities';

// ---

export class GridSystemControlService {
    private _hasCreatedViewSystem: boolean;

    private _currentViewSystemCalculator: CurrentViewSystemCalculator;
    private _minimizedGridCalculator: MinimizedGridCalculator;
    private _normalizedViewSystemCalculator: NormalizedViewSystemCalculator;
    private _originalGridCalculator: OriginalGridCalculator;
    private _outerSquaresFinder: OuterSquaresFinder;
    private _viewportController: ViewportController;
    private _squareLengthCalculator: SquareLengthCalculator;
    private _squareUtilities: SquareUtilities;
    private _viewportRenderingService: ViewportRenderingService;

    // ---

    private _viewportSize!: Size2d;
    private _inspectionStepImageParameter!: InspectionStepImageParameter;
    private _availableSquareInfos!: Array<AvailableSquareInfo>;
    private _inspectionStepResultSquares!: Array<InspectionStepResultSquare>;
    private _innerImage!: HTMLImageElement;
    private _innerCanvas!: HTMLCanvasElement;
    private _minimizedSelectorGrid!: SelectorGrid;
    private _normalizedViewSystemParameters!: ViewSystemParameters;
    private _currentViewSystemParameters!: ViewSystemParameters;

    // ---

    constructor() {
        this._hasCreatedViewSystem = false;

        this._squareLengthCalculator = new SquareLengthCalculator();
        this._squareUtilities = new SquareUtilities(this._squareLengthCalculator);
        this._normalizedViewSystemCalculator = new NormalizedViewSystemCalculator(this._squareUtilities);
        this._originalGridCalculator = new OriginalGridCalculator();
        this._outerSquaresFinder = new OuterSquaresFinder(this._squareUtilities);
        this._viewportController = new ViewportController(new ViewportControlConfigurationParameters(5.0, 0.8, 50));
        this._currentViewSystemCalculator = new CurrentViewSystemCalculator(this._squareUtilities, this._viewportController);
        this._minimizedGridCalculator = new MinimizedGridCalculator(this._outerSquaresFinder, this._squareLengthCalculator);

        // -------------------------------------------------
        
        this._viewportRenderingService = new ViewportRenderingService(this._squareLengthCalculator);
        this._viewportRenderingService.onUpdateRendering = (canvas) => {
            if (this.onUpdateRendering) {
                this.onUpdateRendering(canvas);
            }
        };

        // -------------------------------------------------
        // pre init
        const vps: Size2d = new Size2d(1, 1);
        this._currentViewSystemParameters = new ViewSystemParameters(
            vps,
            new SelectorGrid(Array<number>(), Array<number>(), Array<Square>()),
            new Rectangle(0, 0, 1, 1), new Rectangle(0, 0, 1, 1)
        );
    }

    // ---

    public onUpdateCalculating?: (viewportControlParameters: ViewportControlParameters) => void;
    public onUpdateRendering?: (canvas: HTMLCanvasElement) => void;

    // ---

    public SetViewportControlParameter(zoomFactorMax: number, zoomFactorStepValue: number, offsetPositionStepValue: number) {
        this._viewportController.SetViewportControlConfiguration(zoomFactorMax, zoomFactorStepValue, offsetPositionStepValue);
    }

    // ---

    public SetViewport(changedViewportMask: UserInterfaceChangedViewportOptions): void {
        const promiseCheckViewortChangeable = new Promise((resolve) => {
            if(this._inspectionStepImageParameter.IsViewportChangeable === false) {
                changedViewportMask = UserInterfaceChangedViewportOptions.None;
            }
            resolve();
        });
        
        promiseCheckViewortChangeable.then(() => {
            this._viewportController.UpdateViewportWithButton(changedViewportMask, () => {
                this._currentViewSystemCalculator.CalculateViewSystem(this._normalizedViewSystemParameters, (currentViewSystemParameters) => {
                    this._currentViewSystemParameters = currentViewSystemParameters;
                    this._viewportRenderingService.Rendering(currentViewSystemParameters, this._innerCanvas, this._availableSquareInfos, this._inspectionStepResultSquares, this._inspectionStepImageParameter);
        
                    if(this.onUpdateCalculating) {
                        //todo:
                        this.onUpdateCalculating(this._viewportController.CurrentViewportControlParameters);
                    }
                });
            });
        });
    }

    // ---

    public CanvasHoverFindResultSquareIndex(hoverPosition: Point2d, callback: (index: number) => void): void {
        this._squareUtilities.FindCoordinatesInResultSquare(
            this._currentViewSystemParameters.SelectorGrid,
            this._availableSquareInfos,
            this._inspectionStepResultSquares,
            hoverPosition,
            (index) => {
                callback(index);
            }
        );
    }
    
    // ---

    public CanvasClickGetIndex(clickPosition: Point2d, callback: (index: number) => void): void {
        this._squareUtilities.FindCoordinatesInSquare(
            this._currentViewSystemParameters.SelectorGrid,
            this._availableSquareInfos,
            clickPosition,
            (index) => {
                callback(index);
            }
        );
    }
    
    // ---

    public SetViewportSize(viewportSize: Size2d) {
        this._viewportSize = viewportSize;
        if(this._hasCreatedViewSystem) {
            this._normalizedViewSystemCalculator.CalculateViewSystem(this._minimizedSelectorGrid, this._viewportSize, (normalizedViewSystemParameters) => {
                this._normalizedViewSystemParameters = normalizedViewSystemParameters;
                this.SetViewport(UserInterfaceChangedViewportOptions.None);
            });
        }
    }

    public SetInspectionStepResultSquares(inspectionStepResultSquares: Array<InspectionStepResultSquare>) {
        this._inspectionStepResultSquares = inspectionStepResultSquares;
        this._normalizedViewSystemCalculator.CalculateViewSystem(this._minimizedSelectorGrid, this._viewportSize, (normalizedViewSystemParameters) => {
            this._normalizedViewSystemParameters = normalizedViewSystemParameters;
            this.SetViewport(UserInterfaceChangedViewportOptions.None);
    });
    }

    // ---

    public CreateNewViewSystem(inspectionStepImageParameter: InspectionStepImageParameter,
            availableSquareInfos: Array<AvailableSquareInfo>, inspectionStepResultSquares: Array<InspectionStepResultSquare>,
            viewportSize: Size2d, maskColorAsColor: string, hasResetZoom: boolean): void {
        this._viewportSize = viewportSize;
        this._inspectionStepImageParameter = inspectionStepImageParameter;
        this._availableSquareInfos = availableSquareInfos;
        this._inspectionStepResultSquares = inspectionStepResultSquares;
        this._innerImage = new Image();
        this._innerImage.src = inspectionStepImageParameter.ImageData;
        
        // ---
        const promiseResetZoom = new Promise((resolve) => {
            if(hasResetZoom === true) {
                this._viewportController.ResetViewport();
                this._viewportController.UpdateViewportExplicit(
                    this._inspectionStepImageParameter.ZoomFactor,
                    this._inspectionStepImageParameter.OffsetX,
                    this._inspectionStepImageParameter.OffsetY);
            }
            resolve();
        });
        
        promiseResetZoom.then(() => {
            this._innerImage.onload = () => {
                this._innerCanvas = document.createElement('canvas');
                this._innerCanvas.width = this._innerImage.width;
                this._innerCanvas.height = this._innerImage.height;

                this._originalGridCalculator.CalculateOriginalGrid(
                    this._innerImage,
                    Math.round(inspectionStepImageParameter.SquareSize * inspectionStepImageParameter.ImageScale),
                    (originalSelectorGrid) => {
                        this._minimizedGridCalculator.CalculateMinimizedGrid(this._innerImage, this._innerCanvas,
                            originalSelectorGrid, inspectionStepImageParameter.MaskColor,
                            maskColorAsColor, (minimizedSelectorGrid) => {
                                this._minimizedSelectorGrid = minimizedSelectorGrid; // save as member, its needed in method SetViewportSize
                                this._hasCreatedViewSystem = true; // method SetViewportSize can call rerender now
                                this._normalizedViewSystemCalculator.CalculateViewSystem(this._minimizedSelectorGrid, this._viewportSize, (normalizedViewSystemParameters) => {
                                        this._normalizedViewSystemParameters = normalizedViewSystemParameters;
                                        this.SetViewport(UserInterfaceChangedViewportOptions.None);
                                });
                            });
                    });
            };
        });
    }

    // ---

    public DestroyViewSystem() {
        this._minimizedGridCalculator.DeallocateNativeRenderMemory();
    }
}
