import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=01af0f10&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=ts&"
export * from "./Navbar.vue?vue&type=script&lang=ts&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=01af0f10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01af0f10",
  null
  
)

export default component.exports