import { TooltipContent } from './TooltipContent';

export class InspectionStepResultSquare {
    private _index: number;
    private _backgroundColor: string;
    private _textColor: string;
    private _headerText: string;
    private _tooltipContents: Array<TooltipContent>;
    
    constructor(index: number, backgroundColor: string, textColor: string, headerText: string, tooltipContents: Array<TooltipContent>) {
        this._index = index;
        this._backgroundColor = backgroundColor;
        this._textColor = textColor;
        this._headerText = headerText;
        this._tooltipContents = tooltipContents;
    }

    public get Index() { return this._index; };
    public get BackgroundColor() { return this._backgroundColor; };
    public get TextColor() { return this._textColor; };
    public get HeaderText() { return this._headerText; };
    public get TooltipContents() { return this._tooltipContents; };
};
