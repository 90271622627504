<template>
    <div class="container-fluid">
        <div class="row my-0">
            <button
                    class="btn button-1 mx-0 my-1"
                    @click="toggleVisible()"
            >toggle Visible</button>
        </div>
        <div
          v-if="isGridSystemVisible"
          class="row p-3"
        >
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">click on my squares: .. {{resultSquares1Count}} .. Squares active</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation1"
                            :maskColorAsColor="currentMaskColorAsColor1"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter1"
                            :availableSquareInfos="currentAvailableSquareInfos1"
                            :inspectionStepResultSquares="resultSquares1"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 1)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">click on my squares: .. {{resultSquares2Count}} .. Squares active</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation2"
                            :maskColorAsColor="currentMaskColorAsColor2"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter2"
                            :availableSquareInfos="currentAvailableSquareInfos2"
                            :inspectionStepResultSquares="resultSquares2"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 2)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 1 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation3"
                            :maskColorAsColor="currentMaskColorAsColor3"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter3"
                            :availableSquareInfos="currentAvailableSquareInfos3"
                            :inspectionStepResultSquares="currentInspectionResultSquares3"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 3)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 2 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation4"
                            :maskColorAsColor="currentMaskColorAsColor4"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter4"
                            :availableSquareInfos="currentAvailableSquareInfos4"
                            :inspectionStepResultSquares="currentInspectionResultSquares4"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 4)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 3 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation3"
                            :maskColorAsColor="currentMaskColorAsColor3"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter3"
                            :availableSquareInfos="currentAvailableSquareInfos3"
                            :inspectionStepResultSquares="currentInspectionResultSquares3"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 3)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 4 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation4"
                            :maskColorAsColor="currentMaskColorAsColor4"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter4"
                            :availableSquareInfos="currentAvailableSquareInfos4"
                            :inspectionStepResultSquares="currentInspectionResultSquares4"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 4)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 5 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation3"
                            :maskColorAsColor="currentMaskColorAsColor3"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter3"
                            :availableSquareInfos="currentAvailableSquareInfos3"
                            :inspectionStepResultSquares="currentInspectionResultSquares3"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 3)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card m-3">
                    <div class="card-body">
                        <h3 class="card-title">read from squares 6 (mouse hover)</h3>
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation4"
                            :maskColorAsColor="currentMaskColorAsColor4"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter4"
                            :availableSquareInfos="currentAvailableSquareInfos4"
                            :inspectionStepResultSquares="currentInspectionResultSquares4"
                            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event, 4)"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import axios from 'axios';

import { InspectionStepImageParameter } from '../components/InspectionResultGridSystem/Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from '../components/InspectionResultGridSystem/Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../components/InspectionResultGridSystem/Types/InspectionStepResultSquare';
import { TooltipContent } from '../components/InspectionResultGridSystem/Types/TooltipContent';

import InspectionResultGridSystemAutoResizeWrapper from '../components/InspectionResultGridSystem/InspectionResultGridSystemAutoResizeWrapper';
import { step11, step11result, step12, step12AvailableSquareInfo, step12result } from './dataFiller/dataFiller1';

export default {
    name: 'ResponsiveTest',
    components: {
        InspectionResultGridSystemAutoResizeWrapper
    },
    computed: {
        ...mapGetters([
            'resultSquares1Count',
            'resultSquares2Count',
            'resultSquares1',
            'resultSquares2',
        ]),
    },
    data: function () {
        return {
          isGridSystemVisible: true,

          currentViewportRelation1: 1.9,
          currentMaskColorAsColor1: "#000055",
          currentInspectionStepImageParameter1: undefined,
          currentAvailableSquareInfos1: undefined,
          currentInspectionResultSquares1: undefined,

          currentViewportRelation2: 1.9,
          currentMaskColorAsColor2: "#000055",
          currentInspectionStepImageParameter2: undefined,
          currentAvailableSquareInfos2: undefined,
          currentInspectionResultSquares2: undefined,

          currentViewportRelation3: 1.5,
          currentMaskColorAsColor3: "#000055",
          currentInspectionStepImageParameter3: undefined,
          currentAvailableSquareInfos3: undefined,
          currentInspectionResultSquares3: undefined,

          currentViewportRelation4: 1.0,
          currentMaskColorAsColor4: "#000055",
          currentInspectionStepImageParameter4: undefined,
          currentAvailableSquareInfos4: undefined,
          currentInspectionResultSquares4: undefined,
        };
    },
    created: function() {
        this.demo11();
        this.demo12();
        this.demo13();
        this.demo14();
    },
    methods: {
        toggleVisible() {
            this.isGridSystemVisible = !this.isGridSystemVisible;
        },
        demo11() {
            const imageBuffer = this.loadImage('testImages/01.png');
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  const currentInspectionStepImageParameterObject = step11("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter1 = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
            });

            this.currentAvailableSquareInfos1 = [];

            /////////this.currentInspectionResultSquares1 = this.resultSquares1;//[];
            /*
            const irs = step11result();
            irs.forEach((i) => {
                const ttc = [];
                i.content.forEach((c) => {
                    const cc = new TooltipContent(c.color, c.text);
                    ttc.push(cc);
                });
                const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, ttc);
                this.currentInspectionResultSquares1.push(ii);
            });
            */
        },
        demo12() {
            const imageBuffer = this.loadImage('testImages/02.png');
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  const currentInspectionStepImageParameterObject = step11("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter2 = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
            });

            this.currentAvailableSquareInfos2 = [];
            /*
            const asi = step12AvailableSquareInfo();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos2.push(b);
            });
            */
            /////////this.currentInspectionResultSquares2 = this.resultSquares2;//[];
            /*
            const irs = step12result();
            irs.forEach((i) => {
                const ttc = [];
                i.content.forEach((c) => {
                    const cc = new TooltipContent(c.color, c.text);
                    ttc.push(cc);
                });
                const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, ttc);
                this.currentInspectionResultSquares2.push(ii);
            });
            */
        },
        demo13() {
            const imageBuffer = this.loadImage('testImages/03.png');
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  const currentInspectionStepImageParameterObject = step12("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter3 = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
            });

            this.currentAvailableSquareInfos3 = [];
            const asi = step12AvailableSquareInfo();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos3.push(b);
            });

            this.currentInspectionResultSquares3 = [];
            const irs = step12result();
            irs.forEach((i) => {
                const ttc = [];
                i.content.forEach((c) => {
                    const cc = new TooltipContent(c.color, c.text);
                    ttc.push(cc);
                });
                let headerText = '';
                if (i.headerText !== undefined) {
                  headerText = i.headerText;
                }
                const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
                this.currentInspectionResultSquares3.push(ii);
            });
        },
        demo14() {
            const imageBuffer = this.loadImage('testImages/01.png');
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  const currentInspectionStepImageParameterObject = step11("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter4 = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
            });

            this.currentAvailableSquareInfos4 = [];
            /*
            const asi = step12AvailableSquareInfo();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos4.push(b);
            });
            */
            this.currentInspectionResultSquares4 = [];
            const irs = step11result();
            irs.forEach((i) => {
                const ttc = [];
                i.content.forEach((c) => {
                    const cc = new TooltipContent(c.color, c.text);
                    ttc.push(cc);
                });
                let headerText = '';
                if (i.headerText !== undefined) {
                  headerText = i.headerText;
                }
                const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
                this.currentInspectionResultSquares4.push(ii);
            });
        },
        loadImage(url) {
          return axios
            .get(url, {
              responseType: 'arraybuffer'
            })
            .then((response) => {
              return {
                error: 0,
                errorObject: undefined,
                data: Buffer.from(response.data, 'binary').toString('base64')
              };
            })
            .catch((error) => {
              return {
                error: 1,
                errorObject: error,
                data: undefined
              };
            });
        },
        loadInspectionStepImageParameter(url, initFunction) {
            const imageBuffer = this.loadImage(url);
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  //console.log('Laden OK:', iB.error);
                  const currentInspectionStepImageParameterObject = initFunction("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
              });
        },
        loadAvailableSquareInfos(initFunction) {
            this.currentAvailableSquareInfos = [];
            const asi = initFunction();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos.push(b);
            });
        },
        loadInspectionResultSquares(initFunction) {
          this.currentInspectionResultSquares = [];
          const irs = initFunction();
          irs.forEach((i) => {
              const ttc = [];
              i.content.forEach((c) => {
                const cc = new TooltipContent(c.color, c.text);
                ttc.push(cc);
              });
              let headerText = '';
              if (i.headerText !== undefined) {
                headerText = i.headerText;
              }
              const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
              this.currentInspectionResultSquares.push(ii);
          });
        },
        receiveSquareIndexOnClickCanvas(e, n) {
          ////console.log("system:", n, "event: ", e.index);
          if (n === 1) {
            this.$store.dispatch('toggleResultSquare1', e.index.index);
          } else if (n === 2) {
            this.$store.dispatch('toggleResultSquare2', e.index.index);
          }
        }
    }
};
</script>

<style scoped>
  
  .card {
        min-width: 320px;
        max-width: 800px;
        margin: 0.5rem;
        background-color: #000022;
        border-radius: 0.9rem;
        box-shadow: 1px 1px 8px #6666dd;
  }
</style>>
