import { ViewSystemParameters } from '../Types/ViewSystemParameters';

import { SquareUtilities } from '../Utilities/SquareUtilities';
import { ViewportController } from './ViewportController';
import { Rectangle } from '../Types/_base/Rectangle';
import { SelectorGrid } from '../Types/SelectorGrid';

export class CurrentViewSystemCalculator {
    private _squareUtilities: SquareUtilities;
    private _viewportController: ViewportController;
    
    constructor(squareUtilities: SquareUtilities, viewportController: ViewportController) {
        this._squareUtilities = squareUtilities;
        this._viewportController = viewportController;
    }

    public CalculateViewSystem(sourceViewSystemParameters: ViewSystemParameters, callback: (viewSystemParameters: ViewSystemParameters) => void) {
        const viewportControlParameters = this._viewportController.CurrentViewportControlParameters;
        const viewportSize = sourceViewSystemParameters.ViewportSize;
        const tempGrid = new SelectorGrid(new Array<number>(), new Array<number>(), sourceViewSystemParameters.SelectorGrid.Squares);
        const bitmapRealm = sourceViewSystemParameters.BitmapRealm;
        const sourceBitmapRealmToViewport = sourceViewSystemParameters.BitmapRealmToViewport;

        sourceViewSystemParameters.SelectorGrid.HorizontalSteps.forEach((step) => {
            tempGrid.HorizontalSteps.push(Math.trunc(step * viewportControlParameters.Zoom));
        });
        
        sourceViewSystemParameters.SelectorGrid.VerticalSteps.forEach((step) => {
            tempGrid.VerticalSteps.push(Math.trunc(step * viewportControlParameters.Zoom));
        });
        
        // calculate offset in viewport
        const outerSquaresBoundingBox = this._squareUtilities.GetSquaresBoundingBox(tempGrid.Squares, (outerSquaresBoundingBox) => {
            const gridSystemWidth = tempGrid.HorizontalSteps[outerSquaresBoundingBox.LargestHorizontalStep + 1] - tempGrid.HorizontalSteps[outerSquaresBoundingBox.SmallestHorizontalStep];
            const gridSystemHeight = tempGrid.VerticalSteps[outerSquaresBoundingBox.LargestVerticalStep + 1] - tempGrid.VerticalSteps[outerSquaresBoundingBox.SmallestVerticalStep];
            const offsetGridSystemHorizontal = (viewportSize.Width - gridSystemWidth) / 2;
            const offsetGridSystemVertical = (viewportSize.Height - gridSystemHeight) / 2;
            const destinationGrid = new SelectorGrid(new Array<number>(), new Array<number>(), sourceViewSystemParameters.SelectorGrid.Squares);

            this._viewportController.SetHalfOverlap(offsetGridSystemHorizontal, offsetGridSystemVertical);
            
            tempGrid.HorizontalSteps.forEach((step) => {
                destinationGrid.HorizontalSteps.push(Math.trunc(step + offsetGridSystemHorizontal + viewportControlParameters.OffsetX));
            });

            tempGrid.VerticalSteps.forEach((step) => {
                destinationGrid.VerticalSteps.push(Math.trunc(step + offsetGridSystemVertical + viewportControlParameters.OffsetY));
            });

            const destinationBitmapRealmToViewport = new Rectangle(
                Math.trunc((sourceBitmapRealmToViewport.X * viewportControlParameters.Zoom) + offsetGridSystemHorizontal + viewportControlParameters.OffsetX),
                Math.trunc((sourceBitmapRealmToViewport.Y * viewportControlParameters.Zoom) + offsetGridSystemVertical + viewportControlParameters.OffsetY),
                Math.trunc(sourceBitmapRealmToViewport.Width * viewportControlParameters.Zoom),
                Math.trunc(sourceBitmapRealmToViewport.Height * viewportControlParameters.Zoom));

            callback(new ViewSystemParameters(
                viewportSize,
                destinationGrid,
                bitmapRealm,
                destinationBitmapRealmToViewport));
            });
    }
}
