import { SelectorGrid } from '../Types/SelectorGrid';
import { Square } from '../Types/Square';

import { SquareUtilities } from '../Utilities/SquareUtilities';

export class OuterSquaresFinder {
    private _squareUtilities: SquareUtilities;

    constructor(squareUtilities: SquareUtilities) {
        this._squareUtilities = squareUtilities;
    }

    public GetWithOuterSquares(
        sourceGrid: SelectorGrid,
        callback: (selectorGrid: SelectorGrid) => void
    ): void {
        this._squareUtilities.GetSquaresBoundingBox(sourceGrid.Squares, (squaresBoundingBox) => {
            const newSmallestHorizontalStep = squaresBoundingBox.SmallestHorizontalStep - 1;
            const newLargestHorizontalStep = squaresBoundingBox.LargestHorizontalStep + 1;
            const newSmallestVerticalStep = squaresBoundingBox.SmallestVerticalStep - 1;
            const newLargestVerticalStep = squaresBoundingBox.LargestVerticalStep + 1;

            if (newSmallestHorizontalStep < 0 ||
                newSmallestVerticalStep < 0 ||
                newLargestHorizontalStep + 1 >= sourceGrid.HorizontalSteps.length ||
                newLargestVerticalStep + 1 >= sourceGrid.VerticalSteps.length) {
                    throw new Error("GetWithOuterSquares(): HorizontalSteps or VerticalSteps out of range");
            }

            const stepsHorizontal = newLargestHorizontalStep - newSmallestHorizontalStep + 1;
            const stepsVertical = newLargestVerticalStep - newSmallestVerticalStep;

            for (let i = 0; i < stepsHorizontal; i++) {
                sourceGrid.Squares.push(new Square(-1, newSmallestHorizontalStep + i, newSmallestVerticalStep));
                sourceGrid.Squares.push(new Square(-1, newSmallestHorizontalStep + i, newLargestVerticalStep));
            }

            for (let i = 1; i < stepsVertical; i++) {
                sourceGrid.Squares.push(new Square(-1, newSmallestHorizontalStep, newSmallestVerticalStep + i));
                sourceGrid.Squares.push(new Square(-1, newLargestHorizontalStep, newSmallestVerticalStep + i));
            }

            callback(sourceGrid);
        });
    }
}
