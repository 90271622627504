
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
// ---
import { UserInterfaceChangedViewportOptions } from '../InspectionResultGridSystem/Types/UserInterfaceChangedViewportOptions';

@Component({})
export default class ControlPanel extends Vue {
    @Prop({
        default: function () {
            return "OutText1:";
        }
    })
    outText1!: string;

    @Prop({
        default: function () {
            return "OutText2";
        }
    })
    outText2!: string;

    @Prop({
        default: function () {
            return "OutText3";
        }
    })
    outText3!: string;

    @Prop({
        default: function () {
            return "OutText4";
        }
    })
    outText4!: string;

    @Emit()
    ClickControl(userInterfaceChangedViewportOptions: UserInterfaceChangedViewportOptions) {
        return userInterfaceChangedViewportOptions;
    }
}
