import { Size2d } from './_base/Size2d';
import { Rectangle } from './_base/Rectangle';
import { SelectorGrid } from './SelectorGrid';

export class ViewSystemParameters {
    private _viewportSize: Size2d;
    private _selectorGrid: SelectorGrid;
    private _bitmapRealm: Rectangle;
    private _bitmapRealmToViewport: Rectangle;

    constructor(viewportSize: Size2d, selectorGrid: SelectorGrid, bitmapRealm: Rectangle, bitmapRealmToViewport: Rectangle) {
        this._viewportSize = viewportSize;
        this._selectorGrid = selectorGrid;
        this._bitmapRealm = bitmapRealm;
        this._bitmapRealmToViewport = bitmapRealmToViewport;
    }

    public get ViewportSize() { return this._viewportSize; };
    public get SelectorGrid() { return this._selectorGrid; };
    public get BitmapRealm() { return this._bitmapRealm; };
    public get BitmapRealmToViewport() { return this._bitmapRealmToViewport; };
};
