<template>
    <div class="container-fluid">
        <p>Mount Unmount with AutoResizeWrapper</p>
        <div class="row my-2">
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setVisible()"
            >GSV Visible</button>
             <button
                    class="btn button-1 mx-2 my-1"
                    @click="setUnvisible()"
            >GSV Unvisible</button>
        </div>
        <div class="row my-2">
            <div class="col-xl-9">
                <div class="m-3">
                    <p>----</p>
                    <div
                        v-if="isGridSystemVisible === true"
                    >
                        <InspectionResultGridSystemAutoResizeWrapper
                            :viewportRelation="currentViewportRelation4"
                            :maskColorAsColor="currentMaskColorAsColor4"
                            :inspectionStepImageParameter="currentInspectionStepImageParameter4"
                            :availableSquareInfos="currentAvailableSquareInfos4"
                            :inspectionStepResultSquares="currentInspectionResultSquares4"
                        ></InspectionResultGridSystemAutoResizeWrapper>
                    </div>
                    <div v-else>
                        <p>else no grid</p>
                    </div>
                    <p>----</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

import { InspectionStepImageParameter } from '../components/InspectionResultGridSystem/Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from '../components/InspectionResultGridSystem/Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../components/InspectionResultGridSystem/Types/InspectionStepResultSquare';
import { TooltipContent } from '../components/InspectionResultGridSystem/Types/TooltipContent';

import InspectionResultGridSystemAutoResizeWrapper from '../components/InspectionResultGridSystem/InspectionResultGridSystemAutoResizeWrapper';
import { step11, step11result } from './dataFiller/dataFiller1';

export default {
    name: 'MountUnmountTestWithWrapper',
    components: {
        InspectionResultGridSystemAutoResizeWrapper
    },
    data: function () {
            return {
                isGridSystemVisible: true,

                currentViewportRelation4: 1.0,
                currentMaskColorAsColor4: "#000055",
                currentInspectionStepImageParameter4: undefined,
                currentAvailableSquareInfos4: undefined,
                currentInspectionResultSquares4: undefined,
            };
    },
    created: function() {
            this.demo14();
        },
    methods: {
        setVisible() {
            this.isGridSystemVisible = true;
        },
        setUnvisible() {
            this.isGridSystemVisible = false;
        },
        demo14() {
            const imageBuffer = this.loadImage('testImages/01.png');
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  const currentInspectionStepImageParameterObject = step11("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter4 = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
            });

            this.currentAvailableSquareInfos4 = [];
           
            this.currentInspectionResultSquares4 = [];
            const irs = step11result();
            irs.forEach((i) => {
                const ttc = [];
                i.content.forEach((c) => {
                    const cc = new TooltipContent(c.color, c.text);
                    ttc.push(cc);
                });
                let headerText = '';
                if (i.headerText !== undefined) {
                  headerText = i.headerText;
                }
                const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
                this.currentInspectionResultSquares4.push(ii);
            });
        },
        loadImage(url) {
          return axios
            .get(url, {
              responseType: 'arraybuffer'
            })
            .then((response) => {
              return {
                error: 0,
                errorObject: undefined,
                data: Buffer.from(response.data, 'binary').toString('base64')
              };
            })
            .catch((error) => {
              return {
                error: 1,
                errorObject: error,
                data: undefined
              };
            });
        },
        loadInspectionStepImageParameter(url, initFunction) {
            const imageBuffer = this.loadImage(url);
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  //console.log('Laden OK:', iB.error);
                  const currentInspectionStepImageParameterObject = initFunction("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
              });
        },
        loadAvailableSquareInfos(initFunction) {
            this.currentAvailableSquareInfos = [];
            const asi = initFunction();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos.push(b);
            });
        },
        loadInspectionResultSquares(initFunction) {
          this.currentInspectionResultSquares = [];
          const irs = initFunction();
          irs.forEach((i) => {
              const ttc = [];
              i.content.forEach((c) => {
                const cc = new TooltipContent(c.color, c.text);
                ttc.push(cc);
              });
              let headerText = '';
              if (i.headerText !== undefined) {
                headerText = i.headerText;
              }
              const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
              this.currentInspectionResultSquares.push(ii);
          });
        },
    }
};
</script>
