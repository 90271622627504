import { SquareUtilities } from '../Utilities/SquareUtilities';

import { Size2d } from '../Types/_base/Size2d';
import { Rectangle } from '../Types/_base/Rectangle';
import { SelectorGrid } from '../Types/SelectorGrid';
import { ViewSystemParameters } from '../Types/ViewSystemParameters';

export class NormalizedViewSystemCalculator {
    private _squareUtilities: SquareUtilities;

    constructor(squareUtilities: SquareUtilities) {
        this._squareUtilities = squareUtilities;
    }

    public CalculateViewSystem(sourceGrid: SelectorGrid, viewportSize: Size2d, callback: (viewSystemParameters: ViewSystemParameters) => void) {
        this._squareUtilities.GetSquaresBoundingBox(sourceGrid.Squares, (outerSquaresBoundingBox) => {
            const horizontalLength = sourceGrid.HorizontalSteps[outerSquaresBoundingBox.LargestHorizontalStep + 1] - sourceGrid.HorizontalSteps[outerSquaresBoundingBox.SmallestHorizontalStep];

            const verticalLength = sourceGrid.VerticalSteps[outerSquaresBoundingBox.LargestVerticalStep + 1] - sourceGrid.VerticalSteps[outerSquaresBoundingBox.SmallestVerticalStep];

            const calculatedGridViewSize: Size2d = new Size2d(horizontalLength, verticalLength);
                
            const relationWidth = viewportSize.Width / calculatedGridViewSize.Width;
            const relationHeight = viewportSize.Height / calculatedGridViewSize.Height;

            const normalizedZoom = (relationWidth <= relationHeight) ? relationWidth : relationHeight;

            //// -------------------------------------------------------------------------------------------

            const destinationGrid = new SelectorGrid(new Array<number>(), new Array<number>(), sourceGrid.Squares);
            const horizontalOffset = 0 - sourceGrid.HorizontalSteps[outerSquaresBoundingBox.SmallestHorizontalStep];
            const verticalOffset = 0 - sourceGrid.VerticalSteps[outerSquaresBoundingBox.SmallestVerticalStep];

            sourceGrid.HorizontalSteps.forEach((step) => {
                destinationGrid.HorizontalSteps.push(Math.trunc(((step + horizontalOffset) * normalizedZoom)));
            });

            sourceGrid.VerticalSteps.forEach((step) => {
                destinationGrid.VerticalSteps.push(Math.trunc(((step + verticalOffset) * normalizedZoom)));
            });

            //// -----------------------------------------------------------------------------------------------
                
            const offsetBitmapX = sourceGrid.HorizontalSteps[outerSquaresBoundingBox.SmallestHorizontalStep + 1];
            const offsetBitmapY = sourceGrid.VerticalSteps[outerSquaresBoundingBox.SmallestVerticalStep + 1];

            const newBitmapWidth = sourceGrid.HorizontalSteps[outerSquaresBoundingBox.LargestHorizontalStep] - offsetBitmapX;
            const newBitmapHeight = sourceGrid.VerticalSteps[outerSquaresBoundingBox.LargestVerticalStep] - offsetBitmapY;

            const bitmapRealm = new Rectangle(
                offsetBitmapX,
                offsetBitmapY,
                newBitmapWidth,
                newBitmapHeight);

            //// -----------------------------------------------------------------------------------------------

            const offsetBitmapToViewportX =
                Math.trunc(((sourceGrid.HorizontalSteps[outerSquaresBoundingBox.SmallestHorizontalStep + 1] + horizontalOffset) * normalizedZoom));
            const offsetBitmapToViewportY =
                Math.trunc(((sourceGrid.VerticalSteps[outerSquaresBoundingBox.SmallestVerticalStep + 1] + verticalOffset) * normalizedZoom));

            const bitmapToViewport = new Rectangle(
                offsetBitmapToViewportX,
                offsetBitmapToViewportY,
                Math.trunc((newBitmapWidth * normalizedZoom)),
                Math.trunc((newBitmapHeight * normalizedZoom)));

            //// -------------------------------------
            
            callback(new ViewSystemParameters(
                viewportSize,
                destinationGrid,
                bitmapRealm,
                bitmapToViewport,
            ));
        });
    }
}
