export class Square {
    private _index: number;
    private _startStepHorizontal: number;
    private _startStepVertical: number;

    constructor(index: number, startStepHorizontal: number, startStepVertical: number) {
        this._index = index;
        this._startStepHorizontal = startStepHorizontal;
        this._startStepVertical = startStepVertical;
    }

    public get Index() { return this._index; };
    public get StartStepHorizontal() { return this._startStepHorizontal; };
    public get StartStepVertical() { return this._startStepVertical; };
};
