export class InspectionStepImageParameter {
    private _imageData: string;
    private _offsetX: number;
    private _offsetY: number;
    private _zoomFactor: number;
    private _squareBorderStroke: number;
    private _squareBorderColor: string;
    private _squareFillColor: string;
    private _maskColor: string;
    private _squareSize: number;
    private _imageScale: number;
    private _isViewportChangeable: boolean;

    constructor(
            imageData: string,
            offsetX: number,
            offsetY: number,
            zoomFactor: number,
            squareBorderStroke: number,
            squareBorderColor: string,
            squareFillColor: string,
            maskColor: string,
            squareSize: number,
            imageScale: number,
            isViewportChangeable: boolean) {
        this._imageData = imageData;
        this._offsetX = offsetX;
        this._offsetY = offsetY;
        this._zoomFactor = zoomFactor;
        this._squareBorderStroke = squareBorderStroke;
        this._squareBorderColor = squareBorderColor;
        this._squareFillColor = squareFillColor;
        this._maskColor = maskColor;
        this._squareSize = squareSize;
        this._imageScale = imageScale;
        this._isViewportChangeable = isViewportChangeable;
    }

    public get ImageData() { return this._imageData; };
    public get OffsetX() { return this._offsetX; };
    public get OffsetY() { return this._offsetY; };
    public get ZoomFactor() { return this._zoomFactor; };
    public get SquareBorderStroke() { return this._squareBorderStroke; };
    public get SquareBorderColor() { return this._squareBorderColor; };
    public get SquareFillColor() { return this._squareFillColor; };
    public get MaskColor() { return this._maskColor; };
    public get SquareSize() { return this._squareSize; };
    public get ImageScale() { return this._imageScale; };
    public get IsViewportChangeable() { return this._isViewportChangeable; };
}
