













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, Emit } from 'vue-property-decorator';

import { Size2d } from './Types/_base/Size2d';
import { InspectionStepImageParameter } from './Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from './Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from './Types/InspectionStepResultSquare';

import InspectionResultGridSystem from './InspectionResultGridSystem.vue';

@Component({
    components: { InspectionResultGridSystem }
})
export default class InspectionResultGridSystemAutoResizeWrapper extends Vue {
    private currentViewportSize!: Size2d;

    constructor() {
        super();
        this.currentViewportSize = new Size2d(600, 600);
    }

    mounted() {
        window.addEventListener('resize', this.handleResize);
         this.handleResize();
    }
    
    @Prop({
        default: function () {
            return new Array<AvailableSquareInfo>();
        }
    })
    availableSquareInfos!: Array<AvailableSquareInfo>;

     @Prop({
        default: function () {
            return new Array<InspectionStepResultSquare>();
        }
    })
    inspectionStepResultSquares!: Array<InspectionStepResultSquare>;
       
    @Prop({
        default: function () {
            return 1.6;
        }
    })
    viewportRelation!: number;

    @Watch('viewportRelation')
        // eslint-disable-next-line
        onViewportRelationChanged(value: Size2d, oldValue: Size2d) {
            this.currentViewportSize = new Size2d(100, 100 / this.viewportRelation);
        }
    
    @Prop({
        default: function () {
            return '#ffffff';
        }
    })
    maskColorAsColor!: string;

    @Prop({
        default: function () {
            return new InspectionStepImageParameter(
                '', 0, 0, 1.0, 1, '#ff0000ff', '#ff000080', '#ffffff', 10, 1, true
            );
        }
    })
    inspectionStepImageParameter!: InspectionStepImageParameter;
    
    @Watch('inspectionStepImageParameter', { immediate: false, deep: true })
        // eslint-disable-next-line
        onInspectionStepImageChanged(value: InspectionStepImageParameter, oldValue:InspectionStepImageParameter) {
    }
    
    @Emit()
    sendSquareIndexOnClickCanvas(index: number) {
        return {
            index: index
        };
    }

    handleResize() {
        if(this.$refs.GridSystemWrapperEl !== undefined) {
            const w: number = (this.$refs.GridSystemWrapperEl as Vue & { clientWidth: number }).clientWidth;
            this.currentViewportSize = new Size2d(w, w / this.viewportRelation);
        } /*else {
            console.log('$refs.GridSystemWrapperEl is undefined');
        }*/
    }
};
