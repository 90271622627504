export function step01(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 2,
        squareBorderColor: '#ff000040',
        squareFillColor: '#770000c0',
        maskColor: '#ef2f1f',
        squareSize: 16,
        imageScale: 1.3,
        isViewportChangeable: true,
    };
}

export function step01result() {
    return [
        {
          index: 766,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
          ]
        },
        {
          index: 830,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff0000',
                text: 'Lunker'
              },
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#00ff00',
                text: 'Riss'
              }
          ]
        },
        {
            index: 1035,
            backgroundColor: '#3300ffaa',
            textColor: '#ffffff',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                }
            ]
        },
        {
            index: 1481,
            backgroundColor: '#3300ffaa',
            textColor: '#ffffff',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                },
                {
                    color: '#00ff00',
                    text: 'Riss'
                },
                {
                    color: '#770077',
                    text: 'Fehlstelle'
                }
            ]
        },
        {
            index: 1775,
            backgroundColor: '#3300ffaa',
            textColor: '#ffffff',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle11'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges11'
                }
            ]
        },
        {
            index: 1776,
            backgroundColor: '#ffff0066',
            textColor: '#00aa00',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle22'
                },
            ]
        },
        {
            index: 1932,
            backgroundColor: '#000000bb',
            textColor: '#ffff00',
            headerText: '',
            content: [
                {
                  color: '#ff7777',
                  text: 'Schlagstelle'
                },
                {
                  color: '#aaaaaa',
                  text: 'Sonstiges'
                },
                {
                    color: '#aa00aa',
                    text: 'Verzug'
                },
                {
                  color: '#00aa00',
                  text: 'Lunker'
                },
                {
                  color: '#440000',
                  text: 'Kratzer'
                },
                {
                  color: '#004400',
                  text: 'Oberflächenfehler'
                }
            ]
        },
      ];
}

export function step02(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 1,
        squareBorderColor: '#775500ff',
        squareFillColor: '#77ff00c0',
        maskColor: '#ef2f1f',
        squareSize: 20,
        imageScale: 1.0,
        isViewportChangeable: true,
    };
}

export function step02result() {
  return [
      {
          index: 442,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#aaaaaa',
                text: 'Sonstiges'
              }
          ]
      },
      {
          index: 577,
          backgroundColor: '#3300ffaa',
          textColor: '#ffffff',
          headerText: '',
          content: [
              {
                color: '#ff7777',
                text: 'Schlagstelle'
              },
              {
                color: '#aaaaaa',
                text: 'Sonstiges'
              },
              {
                  color: '#00ff00',
                  text: 'Riss'
              },
              {
                  color: '#770077',
                  text: 'Fehlstelle'
              }
          ]
      },
    ];
}

export function step02AvailableSquareInfo() {
  return [
    {
      index: 442,
      extensionX: 4,
      extensionY: 0,
    },
    {
      index: 650,
      extensionX: 2,
      extensionY: 3,
    },
    {
      index: 577,
      extensionX: 2,
      extensionY: 3,
    },
    {
      index: 924,
      extensionX: 3,
      extensionY: 1,
    }
  ];
}

export function step03(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 2,
        squareBorderColor: '#ffff7740',
        squareFillColor: '#77000077',
        maskColor: '#ef2f1f',
        squareSize: 24,
        imageScale: 1,
        isViewportChangeable: true,
    };
}

export function step04(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 0.3,
        squareBorderColor: '#00ffffff',
        squareFillColor: '#0000ffff',
        maskColor: '#808080',
        squareSize: 8,
        imageScale: 1,
        isViewportChangeable: true,
    };
}

export function step05(imageData) {
    return {
        imageData: imageData,
        offsetX: 0,
        offsetY: 0,
        zoomFactor: 1.0,
        squareBorderStroke: 0.3,
        squareBorderColor: '#ff00ffff',
        squareFillColor: '#80ffffff',
        maskColor: '#808080',
        squareSize: 6,
        imageScale: 1,
        isViewportChangeable: true,
    };
}
