import { SelectorGrid } from '../Types/SelectorGrid';
import { Square } from '../Types/Square';

export class OriginalGridCalculator {
    public CalculateOriginalGrid(bitmap: HTMLImageElement, squareSize: number, callback: (selectorGrid: SelectorGrid) => void): void{
        const originalGrid = new SelectorGrid(new Array<number>(), new Array<number>(), new Array<Square>());
        
        if (squareSize < 1) {
            throw new Error("square size too small");
        }

        let indexCounter = 1;

        let horizontalStepNumber = Math.floor(bitmap.width / squareSize);
        let verticalStepNumber = Math.floor(bitmap.height / squareSize);

        if (bitmap.width % squareSize > 0) {
            horizontalStepNumber++;
        }

        if (bitmap.height % squareSize > 0) {
            verticalStepNumber++;
        }

        ////Info: one more for the end step
        horizontalStepNumber++;
        verticalStepNumber++;

        ////Info: for the outer square rectangle, reserve a step on both sides
        let startPositionStepHorizontal: number = 0 - squareSize;
        let startPositionStepVertical: number = 0 - squareSize;

        ////Info: tow more for the outer square steps
        horizontalStepNumber += 2;
        verticalStepNumber += 2;
        
        //// ----------------------------------------------------------
            
        for (let h = 0; h < horizontalStepNumber; h++) {
            originalGrid.HorizontalSteps.push(startPositionStepHorizontal);
            startPositionStepHorizontal += squareSize;
        }

        for (let v = 0; v < verticalStepNumber; v++) {
            originalGrid.VerticalSteps.push(startPositionStepVertical);
            startPositionStepVertical += squareSize;
        }

        //// ----------------------------------------------------------

        for (let v = 1; v < verticalStepNumber - 1; v++) {
            for (let h = 1; h < horizontalStepNumber - 1; h++) {
                originalGrid.Squares.push(new Square(indexCounter, h, v));
                indexCounter++;
            }
        }
        callback(originalGrid);
    }
}
