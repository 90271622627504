export class Rectangle {
    private _x: number;
    private _y: number;
    private _width: number;
    private _height: number;
    
    constructor(x: number, y: number, width: number, height: number) {
        this._x = x;
        this._y = y;
        this._width = width;
        this._height = height;
    }

    public get X() { return this._x; };
    public get Y() { return this._y; };
    public get Width() { return this._width; };
    public get Height() { return this._height; };
};
