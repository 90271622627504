<template>
  <div class="container-fluid">
    <div class="row mb-0 bg-4 py-1">
        <div class="col-7">
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="demo01()"
            >Demo 01</button>

            <button
                    class="btn button-1 mx-2 my-1"
                    @click="demo02()"
            >Demo 02</button>

            <button
                    class="btn button-1 mx-2 my-1"
                    @click="demo03()"
            >Demo 03</button>

            <button
                    class="btn button-1 mx-2 my-1"
                    @click="demo04()"
            >performance testing 04</button>

            <button
                    class="btn button-1 mx-2 my-1"
                    @click="demo05()"
            >performance testing 05</button>
        </div>
        <div class="col-5">
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setViewport00()"
            >Viewport xxl</button>
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setViewport01()"
            >Viewport big</button>
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setViewport02()"
            >Viewport medium</button>
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setViewport03()"
            >Viewport small</button>
            <button
                    class="btn button-1 mx-2 my-1"
                    @click="setViewport04()"
            >Viewport tiny</button>
        </div>
      </div>
      <div class="row">
        <div class="col-9 bg-2 p-2" style="max-height: 100%; overflow-y: auto;">
          <inspection-result-grid-system
            :viewportControlConfigurationParameters="currentViewportControlConfigurationParameters"
            :viewportSize="currentViewportSize"
            :maskColorAsColor="currentMaskColorAsColor"
            :inspectionStepImageParameter="currentInspectionStepImageParameter"
            :availableSquareInfos="currentAvailableSquareInfos"
            :inspectionStepResultSquares="currentInspectionResultSquares"
            :changedViewport="changedViewport"
            @send-square-index-on-click-canvas="receiveSquareIndexOnClickCanvas($event)"
            @send-viewport-control-parameters="receiveViewportControlParameters($event)"
          ></inspection-result-grid-system>
        </div>
        <div class="col-3 bg-3">
          <control-panel
            :outText1="text1"
            :outText2="text2"
            :outText3="text3"
            :outText4="text4"
            @click-control="receiveFromControlPanel($event)"
          >
          </control-panel>
        </div>
      </div>
      
  </div>
</template>

<script>

// @ is an alias to /src
import axios from 'axios';

import { Size2d } from '../components/InspectionResultGridSystem/Types/_base/Size2d';
import { InspectionStepImageParameter } from '../components/InspectionResultGridSystem/Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from '../components/InspectionResultGridSystem/Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from '../components/InspectionResultGridSystem/Types/InspectionStepResultSquare';
import { TooltipContent } from '../components/InspectionResultGridSystem/Types/TooltipContent';
import InspectionResultGridSystem from '@/components/InspectionResultGridSystem/InspectionResultGridSystem';
import controlPanel from '../components/ControlPanel/ControlPanel';

import { step01, step01result, step02, step02AvailableSquareInfo, step02result, step03, step04, step05 } from './dataFiller/dataFiller';

export default {
  name: 'PerformanceTest',
  components: {
    InspectionResultGridSystem,
    controlPanel
  },
  data: function () {
        return {
          currentViewportSize: new Size2d(1000, 800),
          currentViewportControlConfigurationParameters: {
            ZoomFactorMax: 5.0,
            ZoomFactorStepValue: 0.2,
            OffsetPositionStepValue: 50
          },
          currentMaskColorAsColor: "#000055",
          currentInspectionStepImageParameter: undefined,
          currentAvailableSquareInfos: undefined,
          currentInspectionResultSquares: undefined,
          text1: "test text 1",
          text2: "test text 22",
          text3: "test text 3",
          text4: "",
          changedViewport: 0,
        };
  },
  created: function() {
      this.demo01();
  },
  methods: {
        demo01() {
            this.loadInspectionStepImageParameter('testImages/01.png', step01);
            this.currentAvailableSquareInfos = [];
            this.loadInspectionResultSquares(step01result);
            //--- rounding Test
            // const a = 1.22;
            // const b = 10;
            // const c = Math.ceil(a * b);
            // console.log("roundTest:", c);
        },
        demo02() {
            this.loadInspectionStepImageParameter('testImages/02.png', step02);
            this.loadAvailableSquareInfos(step02AvailableSquareInfo);
            this.loadInspectionResultSquares(step02result);
        },
        demo03() {
            this.loadInspectionStepImageParameter('testImages/03.png', step03);
            this.currentAvailableSquareInfos = [];
            this.currentInspectionResultSquares = [];
        },
        demo04() {
            this.loadInspectionStepImageParameter('testImages/04.bmp', step04);
            this.currentAvailableSquareInfos = [];
            this.currentInspectionResultSquares = [];
        },
        demo05() {
            this.loadInspectionStepImageParameter('testImages/05.bmp', step05);
            this.currentAvailableSquareInfos = [];
            this.currentInspectionResultSquares = [];
        },
        setViewport00() {
          this.currentViewportSize = new Size2d(1200, 940);
        },
        setViewport01() {
          this.currentViewportSize = new Size2d(1000, 800);
        },
        setViewport02() {
          this.currentViewportSize = new Size2d(800, 640);
        },
        setViewport03() {
          this.currentViewportSize = new Size2d(400, 320);
        },
        setViewport04() {
          this.currentViewportSize = new Size2d(320, 200);
        },
        loadImage(url) {
          return axios
            .get(url, {
              responseType: 'arraybuffer'
            })
            .then((response) => {
              return {
                error: 0,
                errorObject: undefined,
                data: Buffer.from(response.data, 'binary').toString('base64')
              };
            })
            .catch((error) => {
              return {
                error: 1,
                errorObject: error,
                data: undefined
              };
            });
        },
        loadInspectionStepImageParameter(url, initFunction) {
            this.text4 = '';
            const imageBuffer = this.loadImage(url);
              imageBuffer.then((iB) => {
                if(iB.error === 1) {
                  console.log('Fehler beim laden:', iB.error);
                  this.currentImageData = undefined;
                } else {
                  //console.log('Laden OK:', iB.error);
                  const currentInspectionStepImageParameterObject = initFunction("".concat("data:image/png;base64,", iB.data));
                  this.currentInspectionStepImageParameter = new InspectionStepImageParameter(
                    currentInspectionStepImageParameterObject.imageData,
                    currentInspectionStepImageParameterObject.offsetX,
                    currentInspectionStepImageParameterObject.offsetY,
                    currentInspectionStepImageParameterObject.zoomFactor,
                    currentInspectionStepImageParameterObject.squareBorderStroke,
                    currentInspectionStepImageParameterObject.squareBorderColor,
                    currentInspectionStepImageParameterObject.squareFillColor,
                    currentInspectionStepImageParameterObject.maskColor,
                    currentInspectionStepImageParameterObject.squareSize,
                    currentInspectionStepImageParameterObject.imageScale,
                    currentInspectionStepImageParameterObject.isViewportChangeable,
                  );
                }
              });
        },
        loadAvailableSquareInfos(initFunction) {
            this.currentAvailableSquareInfos = [];
            const asi = initFunction();
            asi.forEach((a) => {
                const b = new AvailableSquareInfo(a.index, a.extensionX, a.extensionY);
                this.currentAvailableSquareInfos.push(b);
            });
        },
        loadInspectionResultSquares(initFunction) {
          this.currentInspectionResultSquares = [];
          const irs = initFunction();
          irs.forEach((i) => {
              const ttc = [];
              i.content.forEach((c) => {
                const cc = new TooltipContent(c.color, c.text);
                ttc.push(cc);
              });
              let headerText = '';
              if (i.headerText !== undefined) {
                headerText = i.headerText;
              }
              const ii = new InspectionStepResultSquare(i.index, i.backgroundColor, i.textColor, headerText, ttc);
              this.currentInspectionResultSquares.push(ii);
          });
        },
        receiveFromControlPanel(e) {
          this.changedViewport = e;
          setTimeout(() => {
            this.changedViewport = 0; // !!! reset controlButton value from prop
          }, 100);
        },
        receiveSquareIndexOnClickCanvas(e) {
          this.text4 = this.text4.concat("  ".concat(e.index));
        },
        receiveViewportControlParameters(e) {
          this.text1 = "Zoom: " + e.zoom.toString();
          this.text2 = "offsetX: " + e.offsetX.toString() + " | offsetY " + e.offsetY.toString();
          this.text3 = "halfOverlapX: " + e.halfOverlapX.toString() + " | halfOverlapY:  " + e.halfOverlapY.toString();
        }
  }
};
</script>
