import { InspectionStepResultSquare } from '../../../components/InspectionResultGridSystem/Types/InspectionStepResultSquare';
//import { TooltipContent } from '../../../components/InspectionResultGridSystem/Types/TooltipContent';

const resultSquaresModule = {
    state: {
        resultSquares1: [150, 154, 165, 280],
        resultSquares2: [60, 62, 300],

    },
    mutations: {
        ADD_RESULT_SQUARES_1 (state, payload) {
            const rsq = state.resultSquares1;
            rsq.push(payload);
            state.resultSquares1 = rsq;
        },
        REMOVE_RESULT_SQUARES_1 (state, payload) {
            const rsq = state.resultSquares1.filter((i) => i !== payload);
            state.resultSquares1 = rsq;
        },
        ADD_RESULT_SQUARES_2 (state, payload) {
            const rsq = state.resultSquares2;
            rsq.push(payload);
            state.resultSquares2 = rsq;
        },
        REMOVE_RESULT_SQUARES_2 (state, payload) {
            const rsq = state.resultSquares2.filter((i) => i !== payload);
            state.resultSquares2 = rsq;
        },
    },
    actions: {
        toggleResultSquare1({ commit, state }, index) {
            //console.log("action: i ", index);
            //console.log("state1:  ", state.resultSquares1);
            if(index > -1) {
                const iCurrent = state.resultSquares1.find((i) => i === index);
                if(iCurrent === undefined) {
                    commit('ADD_RESULT_SQUARES_1', index);
                } else {
                    commit('REMOVE_RESULT_SQUARES_1', index);
                }
            }
        },
        toggleResultSquare2({ commit, state }, index) {
            if(index > -1) {
                const iCurrent = state.resultSquares2.find((i) => i === index);
                if(iCurrent === undefined) {
                    commit('ADD_RESULT_SQUARES_2', index);
                } else {
                    commit('REMOVE_RESULT_SQUARES_2', index);
                }
            }
        },
    },
    getters: {
        resultSquares1Count: state => state.resultSquares1.length,
        resultSquares2Count: state => state.resultSquares2.length,
        resultSquaresTotalCount: (state) => { return state.resultSquares1.length + state.resultSquares2.length; },
        resultSquares1: (state) => {
            const tempObj = [];
            state.resultSquares1.forEach((i) => {
                const ttc = [];
                const ii = new InspectionStepResultSquare(i, '#3300ffaa', '#ffffff', ttc);
                tempObj.push(ii);
            });
            return tempObj;
        },
        resultSquares2: (state) => {
            const tempObj = [];
            state.resultSquares2.forEach((i) => {
                const ttc = [];
                const ii = new InspectionStepResultSquare(i, '#3300ffaa', '#ffffff', ttc);
                tempObj.push(ii);
            });
            return tempObj;
        },
    }
};

export default resultSquaresModule;
