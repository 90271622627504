







import Vue from 'vue';

import navbar from '@/components/layout/Navbar.vue';

export default Vue.extend({
  components: {
   navbar
  },
});
