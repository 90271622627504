export class AvailableSquareInfo {
    private _index: number;
    private _extensionX: number;
    private _extensionY: number;

    constructor(index: number, extensionX: number, extensionY: number) {
        this._index = index;
        this._extensionX = extensionX;
        this._extensionY = extensionY;
    }

    public get Index() { return this._index; };
    public get ExtensionX() { return this._extensionX; };
    public get ExtensionY() { return this._extensionY; };
}
