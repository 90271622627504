export class SquaresBoundingBox {
    private _smallestHorizontalStep: number;
    private _smallestVerticalStep: number;
    private _largestHorizontalStep: number;
    private _largestVerticalStep: number;

    constructor(smallestHorizontalStep: number, smallestVerticalStep: number,
        largestHorizontalStep: number, largestVerticalStep: number) {
            this._smallestHorizontalStep = smallestHorizontalStep;
            this._smallestVerticalStep = smallestVerticalStep;
            this._largestHorizontalStep = largestHorizontalStep;
            this._largestVerticalStep = largestVerticalStep;
    }

    public get SmallestHorizontalStep() { return this._smallestHorizontalStep; };
    public get SmallestVerticalStep() { return this._smallestVerticalStep; };
    public get LargestHorizontalStep() { return this._largestHorizontalStep; };
    public get LargestVerticalStep() { return this._largestVerticalStep; };
}
