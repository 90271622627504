export class TooltipContent {
    private _color: string;
    private _text: string;
    
    constructor(color: string, text: string) {
        this._color = color;
        this._text = text;
    }

    public get Color() { return this._color; };
    public get Text() { return this._text; };
};
