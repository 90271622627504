import Vue from 'vue';
import Vuex from 'vuex';

import resultSquares from './modules/resultSquares';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    resultSquares,
  }
});

/*
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
});
*/
