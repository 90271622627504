import { SelectorGrid } from '../Types/SelectorGrid';
import { Square } from '../Types/Square';

import { SquareLengthCalculator } from '../Utilities/SquareLengthCalculator';
import { OuterSquaresFinder } from './OuterSquaresFinder';
import { colorValueHexToIntWithAlphaAndSwapRedWithBlue } from '../../../utilities/convertColorValue.js';

import inspectionResultGridSystemNativeServices from '../wasm/inspectionResultGridSystemNativeServices.js';

export class MinimizedGridCalculator {
    private _squareLengthCalculator: SquareLengthCalculator;
    private _outerSquaresFinder: OuterSquaresFinder;

    private _nativeServicesModule: any;

    constructor(outerSquaresFinder: OuterSquaresFinder, squareLengthCalculator: SquareLengthCalculator) {
        this._outerSquaresFinder = outerSquaresFinder;
        this._squareLengthCalculator = squareLengthCalculator;

        this._nativeServicesModule = inspectionResultGridSystemNativeServices();

        this._nativeServicesModule.onRuntimeInitialized = () => {
            ////console.log('WASM runtime initialized!');
        };
    }

    public CalculateMinimizedGrid(
            bitmap: HTMLImageElement,
            innerCanvas: HTMLCanvasElement,
            sourceGrid: SelectorGrid,
            maskColor: string,
            newMaskColor: string,
            callback: (selectorGrid: SelectorGrid) => void
    ): void {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        
        if(ctx != null) {
            ctx.canvas.width = bitmap.width;
            ctx.canvas.height = bitmap.height;
            ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);

            const imgData = ctx.getImageData(0, 0, bitmap.width, bitmap.height);
            const numBytes = bitmap.width * bitmap.height * 4;
            
            // create memoryblock and deepcopy of raw image data for pass to emscripten functions
            const ptr = this._nativeServicesModule._malloc(numBytes);
            const heapBytes: Uint8Array = new Uint8Array(this._nativeServicesModule.HEAPU8.buffer, ptr, numBytes);
            heapBytes.set(new Uint8Array(imgData.data));
            
            const createNewRenderObject = this._nativeServicesModule.cwrap('CreateRenderObject', 'number', ['number', 'number', 'number', 'number', 'number']);
            const res = createNewRenderObject(
                heapBytes.byteOffset,
                bitmap.width,
                bitmap.height,
                colorValueHexToIntWithAlphaAndSwapRedWithBlue(maskColor, 255),
                colorValueHexToIntWithAlphaAndSwapRedWithBlue(newMaskColor, 255),
            );
            
            const isRenderGridVisible = this._nativeServicesModule.cwrap('RenderGridVisible', 'number', ['number', 'number', 'number', 'number']);
            const destinationGrid = new SelectorGrid(sourceGrid.HorizontalSteps, sourceGrid.VerticalSteps, new Array<Square>());

            sourceGrid.Squares.forEach(square => {
                // check whether StartSteps (h/v) of square are in the bitmap area
                ////TODO: build clean code  --> turn the if-branch
                if (sourceGrid.HorizontalSteps[square.StartStepHorizontal] < 0 || sourceGrid.HorizontalSteps[square.StartStepHorizontal] >= bitmap.width) {
                    ////console.log("nop H");
                } else if (sourceGrid.VerticalSteps[square.StartStepVertical] < 0 || sourceGrid.VerticalSteps[square.StartStepVertical] >= bitmap.height) {
                    ////console.log("nop V");
                } else {
                    // if the last square is larger than the bitmap area
                    const horizontal = (sourceGrid.HorizontalSteps[square.StartStepHorizontal] + this._squareLengthCalculator.GetHorizontalSquareLength(sourceGrid, square.Index) >= bitmap.width)
                    ? (bitmap.width - sourceGrid.HorizontalSteps[square.StartStepHorizontal]) - 1
                    : this._squareLengthCalculator.GetHorizontalSquareLength(sourceGrid, square.Index);

                    const vertical = (sourceGrid.VerticalSteps[square.StartStepVertical] + this._squareLengthCalculator.GetVerticalSquareLength(sourceGrid, square.Index) >= bitmap.height)
                    ? (bitmap.height - sourceGrid.VerticalSteps[square.StartStepVertical]) - 1
                    : this._squareLengthCalculator.GetVerticalSquareLength(sourceGrid, square.Index);
                    
                    // add visible squares
                    if(isRenderGridVisible(horizontal, vertical,
                                            sourceGrid.HorizontalSteps[square.StartStepHorizontal],
                                            sourceGrid.VerticalSteps[square.StartStepVertical]) > 0) {
                            destinationGrid.Squares.push(square);
                    };
                }
            });
            
            const ctxInnerCanvas = innerCanvas.getContext('2d');
            if(ctxInnerCanvas !== null) {
                const imageDataInnerCanvas = ctxInnerCanvas.getImageData(0, 0, bitmap.width, bitmap.height);
                imageDataInnerCanvas.data.set(heapBytes);
                ctxInnerCanvas.putImageData(imageDataInnerCanvas, 0, 0);
            }

            this._outerSquaresFinder.GetWithOuterSquares(destinationGrid, (dg) => {
                callback(dg);
            });
        }
    }

    public DeallocateNativeRenderMemory() {
        const DeallocateBitmapMemory = this._nativeServicesModule.cwrap('DeallocateBitmapMemory', 'number');
        DeallocateBitmapMemory();
    }
}
