














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, Emit } from 'vue-property-decorator';
// ---
import ResultToolTip from './ResultToolTip.vue';
// ---
import { Point2d } from './Types/_base/Point2d';
import { Size2d } from './Types/_base/Size2d';
import { ViewportControlConfigurationParameters } from './Types/ViewportControlConfigurationParameters';
import { UserInterfaceChangedViewportOptions } from './Types/UserInterfaceChangedViewportOptions';
import { ViewportControlParameters } from './Types/ViewportControlParameters';
import { InspectionStepImageParameter } from './Types/InspectionStepImageParameter';
import { AvailableSquareInfo } from './Types/AvailableSquareInfo';
import { InspectionStepResultSquare } from './Types/InspectionStepResultSquare';
import { TooltipContent } from './Types/TooltipContent';
// ---
import { GridSystemControlService } from './GridSystemControlService';
// ---
import { uuidv4 } from '../../utilities/createUUID.js';

@Component({
    components: { ResultToolTip }
})
export default class InspectionResultGridSystem extends Vue {
    private canvasId!: string;
    private imageData!: string;
    private imageIn!: HTMLImageElement;
    private toolTipPosition!: Point2d;
    private currentInspectionStepResultSquare!: InspectionStepResultSquare;

    private _gridSystemControlService!: GridSystemControlService;
    
    constructor() {
        super();
        
        this.canvasId = 'grid-system-canvas-'.concat(uuidv4()); // make unique ids for main canvas
        this.imageData = '';
        this.imageIn = new Image();
        this.toolTipPosition = new Point2d(0, 0);
        this.currentInspectionStepResultSquare = new InspectionStepResultSquare(0, '#ffffff', '#ffffff', '', new Array<TooltipContent>());
    }

    @Prop({
        default: function () {
            return {
                zoomFactorMax: 5.0,
                zoomFactorStepValue: 0.2,
                offsetPositionStepValue: 50
            };
        }
    })
    viewportControlConfigurationParameters!: ViewportControlConfigurationParameters;

    @Watch('viewportControlConfigurationParameters', { immediate: false, deep: true })
        // eslint-disable-next-line
        onChangediewportControlParameter(value: ViewportControlConfigurationParameters, oldValue: ViewportControlConfigurationParameters) {
            this._gridSystemControlService.SetViewportControlParameter(value.ZoomFactorMax, value.ZoomFactorStepValue, value.OffsetPositionStepValue);
        }

    @Prop({
        default: function () {
            return new Array<AvailableSquareInfo>();
        }
    })
    availableSquareInfos!: Array<AvailableSquareInfo>;

     @Prop({
        default: function () {
            return new Array<InspectionStepResultSquare>();
        }
    })
    inspectionStepResultSquares!: Array<InspectionStepResultSquare>;

    @Watch('inspectionStepResultSquares', { immediate: false, deep: true })
        // eslint-disable-next-line
        onChangedInspectionStepResultSquares(value: Array<InspectionStepResultSquare>, oldValue: Array<InspectionStepResultSquare>) {
            if(value !== undefined) {
                this._gridSystemControlService.SetInspectionStepResultSquares(value);
            }
        }

    @Prop({
        default: function () {
            return new Size2d(100, 100);
        }
    })
    viewportSize!: Size2d;

    @Watch('viewportSize')
        // eslint-disable-next-line
        onViewportSizeChanged(value: Size2d, oldValue: Size2d) {
            this.setViewportSize(value);
        }
    
    @Prop({
        default: function () {
            return '#ffffff';
        }
    })
    maskColorAsColor!: string;

    @Prop({
        default: function () {
            return new InspectionStepImageParameter(
                '', 0, 0, 1.0, 1, '#ff0000ff', '#ff000080', '#ffffff', 10, 1, true
            );
        }
    })
    inspectionStepImageParameter!: InspectionStepImageParameter;
    
    @Watch('inspectionStepImageParameter', { immediate: false, deep: true })
        // eslint-disable-next-line
        onInspectionStepImageChanged(value: InspectionStepImageParameter, oldValue:InspectionStepImageParameter) {
            this.createNewViewSystem(value);
        }
    
    @Prop({
        default: function () {
            return 0;
        }
    })
    changedViewport!: UserInterfaceChangedViewportOptions;

    @Watch('changedViewport')
        // eslint-disable-next-line
        onChangedViewportChanged(value: number, oldValue: number) {
            if(value !== 0) {
                this._gridSystemControlService.SetViewport(value);
            }
        }

    created() {
        this._gridSystemControlService = new GridSystemControlService();
        
        this._gridSystemControlService.onUpdateRendering = (canvas) => {
            const canvas1 = document.getElementById(this.canvasId) as HTMLCanvasElement;
            
            if(canvas1 !== null) {
                canvas1.style["background-color"] = this.maskColorAsColor;
                const ctx = canvas1.getContext('2d');
                canvas1.width = canvas.width;
                canvas1.height = canvas.height;
                if(ctx !== null) {
                    ctx.clearRect(0, 0, this.viewportSize.Width, this.viewportSize.Height);
                    ctx.drawImage(canvas, 0, 0);
                }
            }
        };

        this._gridSystemControlService.onUpdateCalculating = (param) => {
            this.sendViewportControlParameters(param);
        };
    }
    
    mounted() {
        // for the first inner configuration after created this component and initialize the "GridSystemControlService" instance
        this._gridSystemControlService.SetViewportControlParameter(this.viewportControlConfigurationParameters.ZoomFactorMax,
            this.viewportControlConfigurationParameters.ZoomFactorStepValue, this.viewportControlConfigurationParameters.OffsetPositionStepValue);
        
        this.createNewViewSystem(this.inspectionStepImageParameter);
    }

    setViewportSize(size: Size2d) {
        const canvas1 = document.getElementById(this.canvasId) as HTMLCanvasElement;
        if(canvas1 !== null) {
            canvas1.width = size.Width;
            canvas1.height = size.Height;
            this._gridSystemControlService.SetViewportSize(this.viewportSize);
        }
    }

    createNewViewSystem(inspectionStepImageParameter: InspectionStepImageParameter) {
        this._gridSystemControlService.CreateNewViewSystem(inspectionStepImageParameter, this.availableSquareInfos, this.inspectionStepResultSquares, this.viewportSize, this.maskColorAsColor, true);
    }

    receiveCanvasMouseMove(e: MouseEvent) {
        const canvas1 = document.getElementById(this.canvasId) as HTMLCanvasElement;
        const rect = canvas1.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        this._gridSystemControlService.CanvasHoverFindResultSquareIndex(new Point2d(x, y), (index) => {
            this.toolTipPosition = new Point2d(x, y);
            const isrs = this.inspectionStepResultSquares.find((rs: InspectionStepResultSquare) => rs.Index === index);
            if(isrs === undefined) {
                this.currentInspectionStepResultSquare = new InspectionStepResultSquare(0, '#ffffff', '#ffffff', '', new Array<TooltipContent>());
            } else {
                this.currentInspectionStepResultSquare = isrs;
            }
        });
    }

    receiveCanvasMouseClick(e: MouseEvent) {
        const canvas1 = document.getElementById(this.canvasId) as HTMLCanvasElement;
        const rect = canvas1.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        this._gridSystemControlService.CanvasClickGetIndex(new Point2d(x, y), (index) => {
            this.sendSquareIndexOnClickCanvas(index);
        });
    }

    @Emit()
    sendSquareIndexOnClickCanvas(index: number) {
        return {
            index: index
        };
    }

    @Emit()
    sendViewportControlParameters(viewportControlParameters: ViewportControlParameters) {
        return {
            zoom: viewportControlParameters.Zoom.toFixed(2),
            offsetX: viewportControlParameters.OffsetX,
            offsetY: viewportControlParameters.OffsetY,
            halfOverlapX: viewportControlParameters.HalfOverlapX,
            halfOverlapY: viewportControlParameters.HalfOverlapY,
        };
    }

    destroyed() {
        this._gridSystemControlService.DestroyViewSystem();
    }
}
