import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PerformanceTest from '../views/PerformanceTest.vue';
import ResponsiveTest from '../views/ResponsiveTest.vue';
import MountUnmountTest from '../views/MountUnmountTest.vue';
import MountUnmountTestWithWrapper from '../views/MountUnmountTestWithWrapper.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'PerformanceTest',
    alias: '/performance',
    component: PerformanceTest
  },
  {
    path: '/responsive',
    name: 'ResponsiveTest',
    component: ResponsiveTest
  },
  {
    path: '/mountunmount',
    name: 'MountUnmountTest',
    component: MountUnmountTest
  },
  {
    path: '/mountunmountwithwrapper',
    name: 'MountUnmountTestWithWrapper',
    component: MountUnmountTestWithWrapper
  },
  {
    path: '*',
    redirect: '/'
  }
  // // {
    // // path: '/about',
    // // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // // }
];

const router = new VueRouter({
  routes
});

export default router;
